import React, {ReactNode, useEffect, useState} from 'react'
import { Box, Text } from '@chakra-ui/react'
import { InputError } from './InputError'
import { DropdownFilter } from "../filters/DropdownFilter";
import { ThemeType } from "../../theme";
import { find, uniqueId } from "lodash";
import { COLORS } from "../theme/colors";
import Select from "react-select";
import { MaterialLabel } from "./MaterialHelpers";
import { BASE_INPUT_STYLES } from "../../styles";

export interface SelectOption {
  value: string | any
  label: string | ReactNode
}

export const SelectInput = ( props: {
  label?: string
  placeholder?: string
  isDisabled?: boolean

  options: SelectOption[]
  value: string | any,
  onChange: ( optionValue: string | any ) => void,
  onInputChange?: (searchText: string) => void,
  onBlur?: ( optionValue: string ) => void, // this is used for setTouched on formik forms
  errorText?: string
  variant?: 'basic' | 'material'
  theme?: ThemeType
  border?: 'normal' | 'rounded'
  w?: string
  h?: string
  isClearable?: boolean
  isSearchable?: boolean
  allowFocus?: boolean
} ) => {
  const {
    variant = 'basic',
    w = '100%',
  } = props;
  return (
    <Box w={w}>
      {variant === 'basic' && <BasicSelectInput {...props}/>}
      {variant === 'material' && <MaterialSelectInput {...props}/>}
    </Box>
  )
}

export const BasicSelectInput = ( props: {
  label?: string
  placeholder?: string
  isDisabled?: boolean
  w?: string

  options: SelectOption[]
  value: string,
  onBlur?: ( optionValue: string ) => void,
  onChange: ( optionValue: string ) => void,
  errorText?: string
} ) => {
  const {
    label,
    options,
    value,
    onChange,
    onBlur = () => {},
    placeholder = '',
    isDisabled,
    errorText = '',
  } = props;
  return (
    <>
      {label && (
        <Text textAlign="left" pb="6px" color={isDisabled ? 'shades.neutral.400' : ''}>
          {label}
        </Text>
      )}

      <DropdownFilter
        w='100%'
        options={options}
        placeholder={placeholder}
        value={value}
        onChange={( v ) => {
          // note: blur must be called before change or else the first value set might throw a required error
          onBlur(v.value);
          onChange(v.value);
        }}
        hasError={!!errorText}
      />
      <InputError showError={!!errorText} errorText={errorText}/>
    </>
  )
}


export const MaterialSelectInput = ( props: {
  label?: string
  isDisabled?: boolean
  w?: string
  options: SelectOption[]
  value: string | any,
  onBlur?: ( optionValue: string ) => void,
  onChange: ( optionValue: string ) => void,
  onInputChange?: (inputText: string) => void,
  errorText?: string
  theme?: ThemeType
  border?: 'normal' | 'rounded'
  h?: string
  isClearable?: boolean
  isSearchable?: boolean
  allowFocus?: boolean
} ) => {
  const {
    label,
    options,
    value,
    onInputChange,
    onChange,
    onBlur = () => {},
    isDisabled = false,
    errorText = '',
    theme = 'basic',
    border= 'rounded',
    h= BASE_INPUT_STYLES.height,
    isClearable = true,
    isSearchable = true,
    allowFocus = false
  } = props;

  const selectedOption = value ? find(options, ( option ) => option.value === value) : null
  const componentOverrides = value && isClearable
    ? {
      DropdownIndicator: () => null,
      IndicatorSeparator: () => null,
    }
    : { IndicatorSeparator: () => null }
  const [isFocused, setIsFocused] = useState(false)
  const [hasContent, setHasContent] = useState(!!value && value !== '')

  const elementId = uniqueId('select-')
  useEffect(() => {
    setHasContent(!!value && value !== '')
  }, [value])

  const handleBlur = ( val ) => {
    setIsFocused(false)
    onBlur && onBlur(val)
  }

  const handleFocus = () => {
    setIsFocused(true)
  }
  const handleChange = ( option: { value: string | string[] } | any ) => {
    if (option) {
      onBlur(option.value);
      onChange(option.value);
    } else {
      onBlur('');
      onChange('');
    }
  }
  const themeColor = theme === 'basic' ? 'black' : COLORS[`${theme}_500`]
  return (
    // note: added height here so that the border expanding doesn't shift things below the input on hover
    <Box position='relative' h={BASE_INPUT_STYLES.height}>
      {label && (
        <MaterialLabel
          label={label}
          elementId={elementId}
          isFocused={isFocused}
          isDisabled={isDisabled}
          hasContent={hasContent}
          themeColor={themeColor}
          allowFocus={allowFocus}
        />
      )}
      <Select
        menuPortalTarget={document.body}
        id={elementId}
        onFocus={handleFocus}
        onBlur={( evt ) => handleBlur(evt.target.value)}
        components={componentOverrides}
        isDisabled={isDisabled}
        value={selectedOption}
        styles={materialSelectStyles({ theme, hasError: !!errorText, border, h })}
        placeholder=''
        options={options}
        onInputChange={onInputChange}
        onChange={handleChange}
        isClearable={isClearable}
        isSearchable={isSearchable}
      />
      <InputError showError={!!errorText} errorText={errorText}/>
    </Box>
  )
}

// i left all of the options out even though we dont override much for future tinkering
export const materialSelectStyles = ( params: {
  theme?: 'lavender' | 'peach' | 'linen' | 'midnight' | 'skylight' | string,
  hasError?: boolean,
  border?: 'normal' | 'rounded'
  h?: string
} ) => {
  const { theme = 'basic', hasError = false, border = 'normal'} = params
  const themeVeryDarkhex = !theme || theme !== 'basic' ? COLORS[`${theme}_700`] : COLORS.shades_neutral_400
  const themeDarkhex = !theme || theme !== 'basic' ? COLORS[`${theme}_500`] : 'black'
  const selectedOption = !theme || theme !== 'basic' ? COLORS[`${theme}_100`] : COLORS.lavender_100
  const themeLighthex = !theme || theme !== 'basic' ? COLORS[`${theme}_50`] : COLORS.shades_neutral_100
  const borderRadius = border === 'rounded' ? '50px' : '10px'
  return {
    control: ( defaultStyles, { hasValue, ...rest } ) => {
      return {
        ...defaultStyles,
        cursor: 'pointer',
        padding: '0 8px',
        boxShadow: 'none',
        borderRadius: borderRadius,
        '&:hover': { borderColor: 'gray.300', borderWidth: '2px' }, // border style on hover
        '&:focus': { borderColor: themeVeryDarkhex, borderWidth: '2px' }, // border style on hover
        '&:focus-within': { borderColor: themeVeryDarkhex, borderWidth: '2px' }, // border style on hover
        background: '',
        height: '40px',
        color: 'black',
        border: hasError
          ? `1px solid ${COLORS.danger}`
          : `1px solid ${COLORS.shades_neutral_300}`,
      }
    },
     menuPortal: (base) => ({
      ...base,
      zIndex: 9999, // Ensure menu portal has the highest z-index
    }),
    option: ( defaultStyles, { isSelected, isFocused, ...rest } ) => {
      return {
        ...defaultStyles,
        color: 'black',
        background: isSelected ? selectedOption : isFocused ? themeLighthex : '',
        '&:hover': { background: themeLighthex},
        cursor: 'pointer'
      }
    },
    menu: ( defaultStyles ) => ( {
      ...defaultStyles,
    } ),
    singleValue: ( defaultStyles ) => ( {
      ...defaultStyles,
      color: 'black',
    } ),
    input: ( defaultStyles, { hasValue } ) => ( {
      ...defaultStyles,
      color: themeDarkhex,
    } ),
    valueContainer: ( defaultStyles: any, { hasValue } ) => {
      return {
        ...defaultStyles,
        color: hasValue ? 'white' : '',
        textOverflow: 'ellipsis',

        /* Required for text-overflow to do anything */
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        '&:hover': { color: hasValue ? themeVeryDarkhex : '' },
      }
    },
    placeholder: ( defaultStyles: any, { hasValue } ) => {
      return {
        ...defaultStyles,
        color: hasValue ? 'white' : COLORS.placeholder,
        '&:hover': { color: hasValue ? themeVeryDarkhex : '' },
      }
    },
    dropdownIndicator: ( defaultStyles: any, { hasValue } ) => {
      return {
        ...defaultStyles,
        padding: '0',
        color: themeVeryDarkhex,
        '&:hover': { color: themeVeryDarkhex },
      }
    },
    indicatorSeparator: ( defaultStyles: any, { hasValue } ) => {
      return {
        ...defaultStyles,
        display: 'none',
      }
    },
    clearIndicator: ( defaultStyles: any, { hasValue } ) => {
      return {
        ...defaultStyles,
        padding: '0',
        color: hasValue ? COLORS.text_secondary : '',
        '&:hover': { color: hasValue ? COLORS.text_secondary : '' },
      }
    }
  }
}
