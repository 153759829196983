import { PageLayout } from '../../../mini-lib/layouts/PageLayout'
import { Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { UseViewSize } from '../../../core/UseViewSize'
import { UseInitializeSignupState } from '../../signup/v1/signupHooks'
import { useDispatch } from 'react-redux'
import {
  reduceBillingInterval,
  selectBillingInterval,
} from '../../../data/signup/slice'
import { COLORS } from '../../../mini-lib/theme/colors'
import { Gap } from '../../../mini-lib/gap/Gap'
import { useAppSelector } from '../../../hooks'
import {
  EssentialMonthlySubscriptionCard,
  EssentialYearlySubscriptionCard,
  LuxeMonthlySubscriptionCard,
  LuxeYearlySubscriptionCard,
  SignatureMonthlySubscriptionCard,
  SignatureYearlySubscriptionCard
} from "./Cards";
import {UseQueryParams} from "../../../mini-lib/utils/basic";
import {SBI} from "../../../integrations/stripe/constants";

export const CheckoutV2SubscriptionPage = () => {

  UseInitializeSignupState()
  const billingInterval = useAppSelector(selectBillingInterval)

  const queryParams = UseQueryParams()
  const interval = queryParams.get('billing_interval')
  return (
    <>
      <PageLayout
        hasUserNav={false}
        variant="full"
        header={<></>}
        content={
          <>
            <Box maxW='500px' margin='auto'>
              <Text textAlign='center' variant='title1'>FIND THE BEST SALONSCALE PLAN FOR YOU</Text>
              <Gap s='12px'/>
              <Text fontSize='14px' textAlign='center' color={COLORS.midnight_500}>The #1 choice for backbar management. Effortlessly capture color costs, client formulas, and optimize inventory for maximum profitability.</Text>
            </Box>
            <Gap/>

            {/*
            note: this may be considered a bug in the future
                  we are hiding the toggle based on the query param
                  because we dont want users using the monthly coupons with the yearly subscriptions
            */}
            {!interval && (
              <>
                <ToggleSubscriptionType />
                <Gap s='12px'/>
                <Text fontSize='12px' textAlign='center' color={COLORS.midnight_500}>(Save 15% with Annual Pricing!)</Text>
              </>
            )}


            {billingInterval === SBI.month && <MonthlySubscriptions />}
            {billingInterval === SBI.year && <YearlySubscriptions/>}
          </>
        }
      />
    </>
  )
}

export const ToggleSubscriptionType = () => {
  const dispatch = useDispatch()
  const billingInterval = useAppSelector(selectBillingInterval)

  return (
    <Flex justify='center'>
      <Flex borderRadius='50px' border={`1px solid ${COLORS.midnight_500}`} maxW='100%' cursor='pointer'>
        <Flex onClick={() => dispatch(reduceBillingInterval(SBI.month))} justify='center' fontWeight='bold' w='120px' p='6px 12px' borderRadius='50px' bg={billingInterval === SBI.month ? COLORS.midnight_900 : 'white'} color={billingInterval === SBI.month ? 'white' : COLORS.midnight_900} >MONTHLY</Flex>
        <Flex onClick={() => dispatch(reduceBillingInterval(SBI.year))} justify='center' fontWeight='bold' w='120px' p='6px 12px' borderRadius='50px' bg={billingInterval === SBI.year ? COLORS.midnight_900 : 'white'} color={billingInterval === SBI.year ? 'white' : COLORS.midnight_900}>ANNUAL</Flex>
      </Flex>
    </Flex>
  )
}
export const MonthlySubscriptions = () => {
  const { isMobile } = UseViewSize()
  return (
    <>
      <Flex
        justify="center"
        align="center"
        gridGap={'24px'}
        m={isMobile ? '32px 12px' : '32px 0'}
        wrap={isMobile ? 'wrap' : 'nowrap'}
      >
        <EssentialMonthlySubscriptionCard />
        <SignatureMonthlySubscriptionCard />
        <LuxeMonthlySubscriptionCard />
      </Flex>
    </>
  )
}

export const YearlySubscriptions = () => {
  const { isMobile } = UseViewSize()
  return (
    <>
      <Flex
        justify="center"
        align="center"
        gridGap={'24px'}
        m={isMobile ? '32px 12px' : '32px 0'}
        wrap={isMobile ? 'wrap' : 'nowrap'}
      >
        <EssentialYearlySubscriptionCard />
        <SignatureYearlySubscriptionCard />
        <LuxeYearlySubscriptionCard />
      </Flex>
    </>
  )
}

