import { useAppSelector } from '../../../hooks'
import {
  reduceBillingInterval,
  reduceSetSalonType,
  reduceSetScaleQuantities,
  reduceSetSelectedFreeScale,
  reduceSetSubscriptionType,
  selectSalonType,
  selectScaleQuantities,
  selectSelectedFreeScale,
  selectSubscriptionType,
} from '../../../data/signup/slice'
import { CBSalonType, CBSubscriptionType, QPS } from '../../../integrations/chargebee/constants'
import {assign, keys} from 'lodash'
import {
  BLACK_MINI_SCALE,
  BLACK_SCALE,
  LAVENDER_SCALE,
  LINEN_SCALE,
  MIDNIGHT_SCALE,
  PEACH_SCALE
} from '../../../data/signup/constants'
import { Scale } from '../../../data/signup/interfaces'
import { useDispatch } from 'react-redux'
import { UseQueryParams } from '../../../mini-lib/utils/basic'
import { useEffect } from 'react'
import { buildUtmQueryParamString, UseUtmQueryParams } from '../../../integrations/utm'
import { buildGrinQueryParamString, UseGrinQueryParams } from '../../../integrations/grin/utils'
import { getCountryCode } from "../../../mini-lib/time/utils";
import {normalizeBillingFrequency} from "../../../integrations/stripe/utils";

export const UseBuildSignupQueryParams = (overrides?: { subscriptionType?: CBSubscriptionType; salonType?: CBSalonType, freeScale?: Scale, scaleQuantities?: { [key: string]: number } }) => {
  let queryParams = '?'
  const subscriptionType = useAppSelector(selectSubscriptionType)
  const salonType = useAppSelector(selectSalonType)
  const scaleQuantities = useAppSelector(selectScaleQuantities)
  const freeScale = useAppSelector(selectSelectedFreeScale)
  const utmParams = UseUtmQueryParams()
  const utmQueryParamString = buildUtmQueryParamString(utmParams)
  const grinParams = UseGrinQueryParams()
  const grinQueryParamString = buildGrinQueryParamString(grinParams)

  if (subscriptionType && salonType) {
    // there is a race condition between the link on click and the button set state on click
    // the link sets the param and then the button sets the state but then the param overrides the buttons state on the next page
    if (overrides && overrides.subscriptionType && overrides.salonType) {
      queryParams += `${QPS.plan}=${overrides.salonType}-${overrides.subscriptionType}&`
    } else {
      queryParams += `${QPS.plan}=${salonType}-${subscriptionType}&`
    }
  }
  // for now we want a free scale for everyone
  if (freeScale || overrides?.freeScale) {
    queryParams += overrides?.freeScale
      ? `${QPS.freescale}=${overrides.freeScale.id}&`
      : freeScale ? `${QPS.freescale}=${freeScale.id}&` : ''
  }
  if (utmQueryParamString) {
    queryParams += utmQueryParamString
  }
  if (grinQueryParamString) {
    queryParams += grinQueryParamString
  }
  const joinedScaleQuantities = assign({}, scaleQuantities, overrides?.scaleQuantities || {})
  if (joinedScaleQuantities) {
    keys(joinedScaleQuantities).forEach((scaleColor) => {
      const quantity = joinedScaleQuantities[scaleColor]
      if (quantity > 0) {
        queryParams += `${scaleColor}=${quantity}&`
      }
    })
  }
  return queryParams
}

export const extractPlanFromQueryParam = (
  qp: string | null,
): { salonType: CBSalonType; subscriptionType: CBSubscriptionType } | null => {
  if (!qp) {
    return null
  }
  if (qp === 'solo-bundle' || qp === 'solo-yearly') {
    return { salonType: 'solo', subscriptionType: 'yearly' }
  }
  if (qp === 'team-bundle' || qp === 'team-yearly') {
    return { salonType: 'team', subscriptionType: 'yearly' }
  }
  if (qp === 'solo-monthly') {
    return { salonType: 'solo', subscriptionType: 'monthly' }
  }
  if (qp === 'team-monthly') {
    return { salonType: 'team', subscriptionType: 'monthly' }
  }
  return null
}

export const extractScalesFromQueryParams = (params: {
  peach: string | null
  black: string | null
  lavender: string | null
  linen: string | null
  midnight: string | null
  blackmini: string | null
}): { [key: string]: number } => {
  const { midnight = 0, lavender = 0, peach = 0, linen = 0, black = 0, blackmini = 0 } = params
  const scaleMap: { [key: string]: number } = {}
  scaleMap[MIDNIGHT_SCALE.id] = midnight ? parseInt(midnight) : 0
  scaleMap[LAVENDER_SCALE.id] = lavender ? parseInt(lavender) : 0
  scaleMap[BLACK_SCALE.id] = black ? parseInt(black) : 0
  scaleMap[PEACH_SCALE.id] = peach ? parseInt(peach) : 0
  scaleMap[LINEN_SCALE.id] = linen ? parseInt(linen) : 0
  scaleMap[BLACK_MINI_SCALE.id] = blackmini ? parseInt(blackmini) : 0
  return scaleMap
}

export const extractFreeScaleFromQueryParam = (params: { freescale: string | null }): Scale | null => {
  const { freescale } = params

  if (freescale === LAVENDER_SCALE.id) {
    return LAVENDER_SCALE
  }
  if (freescale === BLACK_MINI_SCALE.id) {
    return BLACK_MINI_SCALE
  }
  if (freescale === MIDNIGHT_SCALE.id) {
    return MIDNIGHT_SCALE
  }

  if (freescale === BLACK_SCALE.id) {
    return BLACK_SCALE
  }
  if (freescale === PEACH_SCALE.id) {
    return PEACH_SCALE
  }
  if (freescale === LINEN_SCALE.id) {
    return LINEN_SCALE
  }
  return null
}

export const extractStateFromQueryParams = (params: {
  plan: string | null
  peach: string | null
  black: string | null
  blackmini: string | null
  midnight: string | null
  lavender: string | null
  linen: string | null
  freescale: string | null
}): {
  extractedPlan: { salonType: CBSalonType; subscriptionType: CBSubscriptionType } | null
  extractedScales: { [key: string]: number }
  extractedFreeScale: Scale | null
} => {
  const { plan, peach, black, midnight, lavender, linen, blackmini, freescale } = params

  const extractedPlan = extractPlanFromQueryParam(plan)
  const extractedScales = extractScalesFromQueryParams({ blackmini, peach, black, midnight, lavender, linen })
  const extractedFreeScale = extractFreeScaleFromQueryParam({ freescale })

  return { extractedPlan, extractedScales, extractedFreeScale }
}

export const UseInitializeSignupState = () => {
  const dispatch = useDispatch()
  const queryParams = UseQueryParams()
  const plan = queryParams.get(QPS.plan)
  const interval = queryParams.get(QPS.interval)
  const peach = queryParams.get(QPS.peach)
  const black = queryParams.get(QPS.black)
  const midnight = queryParams.get(QPS.midnight)
  const lavender = queryParams.get(QPS.lavender)
  const linen = queryParams.get(QPS.linen)
  const blackmini = queryParams.get(QPS.blackmini)
  const freescale = queryParams.get(QPS.freescale)

  useEffect(() => {
    const { extractedPlan, extractedFreeScale, extractedScales } = extractStateFromQueryParams({
      plan,
      peach,
      black,
      midnight,
      lavender,
      linen,
      blackmini,
      freescale,
    })
    if (interval) {
      const normalizedInterval = normalizeBillingFrequency(interval)
      dispatch(reduceBillingInterval(normalizedInterval))
    }
    if (extractedPlan) {
      dispatch(reduceSetSubscriptionType(extractedPlan.subscriptionType))
      dispatch(reduceSetSalonType(extractedPlan.salonType))
    }
    if (extractedFreeScale) {
      dispatch(reduceSetSelectedFreeScale(extractedFreeScale))
    }
    if (extractedScales) {
      dispatch(reduceSetScaleQuantities(extractedScales))
    }
  }, [dispatch, plan, peach, black, midnight, lavender, linen, freescale, blackmini, interval])
}

export const UseCanSeeScales = (): boolean => {
  const countryCode = getCountryCode();
  return countryCode === "US" || countryCode === "CA"
}
