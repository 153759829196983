export const STRIPE_LOADING = 'stripe-loading'
export const STRIPE_CREATE = 'stripe-create'
export type StripePlanType = 'essentials' | 'signature' | 'luxe' | 'inventory'
export type StripeBillingInterval = 'month' | 'year'
export const STRIPE_BILLING_INTERVAL = {
  month: 'month',
  year: 'year'
}
export const SBI = STRIPE_BILLING_INTERVAL

export const PLANS: {[key: string]: {id: StripePlanType, title: string, interval: StripeBillingInterval, per_month: number, per_year: number}} = {
  // paying monthly
  essential_monthly: {id: 'essentials', title: 'Essentials', interval: 'month', per_month: 55, per_year: 660},
  signature_monthly: {id: 'signature', title: 'Signature', interval: 'month', per_month: 149, per_year: 1788},
  luxe_monthly: {id: 'luxe', title: 'Luxe', interval: 'month', per_month: 199, per_year: 2388},

  // paying yearly
  essential_yearly: {id: 'essentials', title: 'Essentials', interval: 'year', per_month: 47, per_year: 561},
  signature_yearly: {id: 'signature', title: 'Signature', interval: 'year', per_month: 127, per_year: 1520},
  luxe_yearly: {id: 'luxe', title: 'Luxe', interval: 'year', per_month: 169, per_year: 2030},
  inventory: {id: 'inventory', title: 'Inventory', interval: 'month', per_month: 49, per_year: 588}
}

export const STRIPE_COUPON_IDS=  {
  square_50_staging: '7A9QAv3R',
  square_50: 'g12PP2cP'
}