import React, {useEffect, useState} from 'react'
import { Flex } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../hooks'
import {generatePath} from 'react-router-dom'
import { selectActiveSalonContext, selectSalons } from '../../data/salon/slice'
import { dispatchLaraListSalons } from '../../data/salon/api'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { ROUTES } from "../../appRoutes";
import logoWithName from '../../assets/logo-with-name.svg'
import {SelectInput} from "../../mini-lib";
import {Gap} from "../../mini-lib/gap/Gap";
import {getEnvConfig} from "../../config";
import {Loading} from "../../mini-lib/loading/Loading";

export const MultiSalonButton = () => {
  const activeSalon = useAppSelector(selectActiveSalonContext)

  return (
    <>
      <Flex align="center" m="24px">
        <img alt="logo" style={{ height: '100%', width: '100%', maxWidth: '160px', padding: '4px' }} src={logoWithName} />
      </Flex>
      {/*<Box onClick={() => dispatch(reduceIsMultiSalonSideSheetVisible(true))}>*/}
      {/*{activeSalon && <MultiSalonTile salonRow={activeSalon} iconName="chevron_right" />}*/}
      {/*</Box>*/}
      <Flex justify="center" p='0 24px' h='40px'>
        {activeSalon ? <MultiSalonSelect/> : <Loading/>}
      </Flex>
      <Gap s='12px'/>
      {/*<MultiSalonSideSheet />*/}
    </>
  )
}
//
// export const MultiSalonSideSheet = () => {
//   const dispatch = useDispatch()
//   const navVisible = useAppSelector(selectIsMultiSalonSideSheetVisible)
//
//   return (
//     <>
//       <Drawer placement="left" onClose={() => dispatch(reduceIsMultiSalonSideSheetVisible(false))} isOpen={navVisible}>
//         <DrawerOverlay />
//         <DrawerContent padding={0}>
//           <DrawerBody padding={0}>
//             <MultiSalonSelect />
//           </DrawerBody>
//         </DrawerContent>
//       </Drawer>
//     </>
//   )
// }

export const MultiSalonSelect = () => {
  const dispatch = useDispatch()

  const { user } = UseBaseApiParams()
  const activeSalon = useAppSelector(selectActiveSalonContext)
  const salons = useAppSelector(selectSalons)
  const loaded = !!salons

  useEffect(() => {
    if (user?.token && !loaded) {
      dispatch(dispatchLaraListSalons({ token: user.token, userId: user.userId }))
    }
  }, [dispatch, user?.token, user?.userId, loaded])

  const config = getEnvConfig()
  const [selectedId, setSelectedId] = useState(activeSalon?.id)
  const salonOptions = salons ? salons.map(salon => {
    return {
      label: salon.name,
      value: salon.id
    }
  }) : []
  return (
    <SelectInput
      variant='material'
      border='normal'
      value={selectedId}
      isClearable={false}
      isSearchable={false}
      options={salonOptions}
      onChange={(optionValue) => {
        setSelectedId(optionValue)
        const url = `${config.reactHost}${generatePath(ROUTES.sessions, { salonId: optionValue })}`
        window.location.href = url
      }}
      allowFocus={true}
    />
  )
}
//
// export const MultiSalonTile = (props: {
//   salonRow: Salon
//   isSelected?: boolean
//   showIcon?: boolean
//   iconName?: string
//   link?: string
//   iconLink?: string
// }) => {
//   const { salonRow, showIcon = true, isSelected = false, iconName = 'chevron_right', link, iconLink } = props
//   const dispatch = useDispatch()
//
//   const [isHover, setIsHover] = React.useState(false)
//   const logoUrl = salonRow.photoUrl || SALONSCALE_LOGO_URL
//
//   return (
//     <Flex
//       p="0 24px"
//       cursor="pointer"
//       justify="space-between"
//       align="center"
//       onMouseOver={() => setIsHover(true)}
//       onMouseOut={() => setIsHover(false)}
//       _hover={{ bg: COLORS.hover }}
//       bg={isSelected ? COLORS.midnight_50 : ''}
//       color={isSelected ? COLORS.lavender_500 : ''}
//     >
//       <Flex
//         padding="12px 0"
//         align="center"
//         flex="1"
//         gridGap="12px"
//         onClick={() => {
//           if (link) {
//             window.location.href = link
//             dispatch(reduceIsMultiSalonSideSheetVisible(false))
//           }
//         }}
//       >
//         <Flex justify="center" align="center">
//           <Flex
//             justify="center"
//             align="center"
//             w="38px"
//             h="38px"
//             p="2px"
//             borderRadius="50px"
//             bg={COLORS.lavender_100}
//             border={isSelected || isHover ? `1px solid ${COLORS.lavender_500}` : ''}
//             overflow='hidden'
//           >
//             <Img h="100%" w="100%" src={logoUrl} borderRadius="50%" bg={COLORS.lavender_50} />
//           </Flex>
//         </Flex>
//
//         <Flex direction="column" justify="center">
//           <Text fontWeight="bold" fontSize="14px">
//             {salonRow.name}
//           </Text>
//         </Flex>
//       </Flex>
//       {showIcon && (
//         <Flex
//           pl='12px'
//           _hover={{ bg: COLORS.hover }}
//           borderRadius="100%"
//           onClick={(e) => {
//             if (iconLink) {
//               e.preventDefault()
//               window.location.href = iconLink
//               dispatch(reduceIsMultiSalonSideSheetVisible(false))
//             }
//           }}
//         >
//           <MaterialIcon name={iconName} colorhex={COLORS.text_secondary} />
//         </Flex>
//       )}
//     </Flex>
//   )
// }