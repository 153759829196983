import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setNavVisible, setSubSidenavVisible } from './slice'
import { generatePath, Link, useHistory, useLocation, useParams } from 'react-router-dom'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Spinner,
  Text
} from '@chakra-ui/react'
import { UseViewSize } from '../../core/UseViewSize'
import { useAppSelector } from '../../hooks'
import { selectLoggedInUserSalonPermissions } from '../../data/user/slice'
import {ReleaseLabour, ReleaseMultiLocation, ReleaseOpenDay, ReleaseStartGuideV2, ReleaseStripe , ReleaseAddonV2 } from '../flags/Release'
import { COLORS } from '../theme/colors'
import {getCurrentPaymentStatus, isSuperUser, userHasInventoryPermissions} from '../../data/user/utils'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { SalonScaleIcon } from '../icons/SalonScaleIcon'
import { USER_PERMISSIONS } from '../../data/user/constants'
import { DisableAutofocus } from '../disable-autofocus/DisableAutofocus'
import { Sidenav, SidenavHeader, SidenavTile } from './Sidenav'
import { SubSidenav } from './SubSidenav'
import { selectChecklistItemsByCode } from "../../data/start-guide/slice";
import { dispatchListUserChecklistItems } from "../../data/start-guide/api";
import { CHECKLIST_CODES } from "../../data/start-guide/constants";
import { selectActiveSalonContext } from "../../data/salon/slice";
import {buildPathToPageOrGuide, getShowTeam} from './utils'
import { ROUTES } from "../../appRoutes";

export const OwnerSidenav = (props: { children: any }) => {
  const { children } = props
  const releaseMultiLocation = ReleaseMultiLocation()
  const { user } = UseBaseApiParams()
  const location = useLocation()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setSubSidenavVisible(Boolean(location.pathname.includes('reports'))))
  }, [dispatch, location, location.pathname])

  return (
    <>
      <Sidenav
        header={<SidenavHeader />}
        showLogout={!releaseMultiLocation}
        showStopImpersonating={user && isSuperUser(user)}
        content={<OwnerSidenavLinks />}
        subNav={<SubSidenav content={<OwnerSubSidenavLinks />} />}
      >
        {children}
      </Sidenav>
    </>
  )
}

export const OwnerSidenavLinks = () => {
  const dispatch = useDispatch()

  const { isMobile, isTablet } = UseViewSize()
  const params: any = useParams()
  const history = useHistory()
  const { salonId } = params
  const { user } = UseBaseApiParams()

  const userPermissions = useAppSelector(selectLoggedInUserSalonPermissions)
  if (!salonId) {
    history.push(generatePath(ROUTES.base))
  }
  const releaseStartGuideV2 = ReleaseStartGuideV2()
  const releaseOpenDay = ReleaseOpenDay()
  const releaseLabor = ReleaseLabour()
  const releaseMultiSalon = ReleaseMultiLocation()
  const checklistItems = useAppSelector(selectChecklistItemsByCode) || {}
  const salon = useAppSelector(selectActiveSalonContext)
  const releaseAddonV2 = ReleaseAddonV2()

  const releaseStripe = ReleaseStripe()
  const paymentStatus = getCurrentPaymentStatus(user)

  useEffect(() => {
    if (user?.token && user?.userId) {
      dispatch(dispatchListUserChecklistItems(user.token, user.userId))
    }
  }, [dispatch, user?.token, user?.userId])

  const location = useLocation()
  const isSelected = (route: string): boolean => location.pathname.includes(route)

  const mobileDismissNav = () => {
    if (isMobile || isTablet) {
      dispatch(setNavVisible(false))
    }
  }
  const linesCompleteOrHasData = checklistItems[CHECKLIST_CODES.addPricing]?.completed || checklistItems[CHECKLIST_CODES.autoPricing]?.completed || (!!salon?.productsCount && salon.productsCount > 0)
  const inventoryCompleteOrHasData = (checklistItems[CHECKLIST_CODES.inventoryIntroduction]?.completed && checklistItems[CHECKLIST_CODES.inventorySetTargets]?.completed && checklistItems[CHECKLIST_CODES.inventorySetOnHand]?.completed)
    || (!!salon && salon.colorsCount > 0 && salon.colorsPricedCount > 0 && salon.targetSetCount > 0)
  const clientsCompleteOrHasData = checklistItems[CHECKLIST_CODES.addClient]?.completed || (!!salon && salon.clientsCount > 0)

  const addonsCompleteOrHasData = checklistItems[CHECKLIST_CODES.addAddons]?.completed || (!!salon && salon.addonsCount > 0)
  const extensionsCompleteOrHasData = checklistItems[CHECKLIST_CODES.addExtensions]?.completed || (!!salon && salon.extensionsCount > 0 && salon.extensionsPricedCount > 0)
  const teamCompleteOrHasData = checklistItems[CHECKLIST_CODES.addStylist]?.completed || (!!salon && salon.teamCount > 1)
  const laborCompleteOrHasData = checklistItems[CHECKLIST_CODES.addLabor]?.completed || (!!salon && salon.laborsCount > 0)

  const hasInventoryAndOrders = releaseStripe ? paymentStatus && paymentStatus.hasInventory : true
  const showTeam = getShowTeam(user)

  return (
    <Box overflowX="scroll">
      {!userPermissions && (
        <Flex justify="center" mt="24px">
          <Spinner />
        </Flex>
      )}
      {userPermissions && (
        <>
          {userPermissions?.permissions.includes(USER_PERMISSIONS.home) && (
            <Link onClick={mobileDismissNav} to={generatePath(ROUTES.home, { salonId })}>
              <SidenavTile iconName="home" label="Home" isSelected={isSelected('home')} />
            </Link>
          )}
          {(userPermissions?.permissions.includes(USER_PERMISSIONS.colorLines) ||
            userPermissions?.permissions.includes(USER_PERMISSIONS.addons) ||
            userPermissions?.permissions.includes(USER_PERMISSIONS.extensions)) ? (
            <Accordion allowToggle p="0">
              <AccordionItem border="none" p="0">
                <AccordionButton p="12px 24px">
                  <Flex w="100%" justify="space-between" align="center">
                    <Flex align="center" gridGap="12px">
                      <SalonScaleIcon name="products" />
                      Back Bar
                    </Flex>
                    <AccordionIcon h="24px" w="24px" color={COLORS.text_secondary} />
                  </Flex>
                </AccordionButton>
                <AccordionPanel p={0}>
                  {userPermissions?.permissions.includes(USER_PERMISSIONS.colorLines) && (
                    <Link
                      onClick={mobileDismissNav}
                      to={buildPathToPageOrGuide({route: ROUTES.lines, guideRoute: ROUTES.sgAddLinesIntro, salonId, guideCompleteOrHasData: linesCompleteOrHasData, releaseStartGuideV2})}
                    >
                      <SidenavTile label="Color Lines" isSelected={isSelected('lines')} />
                    </Link>
                  )}
                  {releaseAddonV2 && userPermissions?.permissions.includes(USER_PERMISSIONS.addons) ? (
                    <Link
                      onClick={mobileDismissNav}
                      to={buildPathToPageOrGuide({route: ROUTES.suppliesV2, guideRoute: ROUTES.sgAddAddonsIntro, salonId, guideCompleteOrHasData: addonsCompleteOrHasData, releaseStartGuideV2})}
                    >
                      <SidenavTile label="Add-Ons" isSelected={isSelected('addons')} />
                    </Link>
                  ) : (
                      <Link
                      onClick={mobileDismissNav}
                      to={buildPathToPageOrGuide({route: ROUTES.supplies, guideRoute: ROUTES.sgAddAddonsIntro, salonId, guideCompleteOrHasData: addonsCompleteOrHasData, releaseStartGuideV2})}
                    >
                      <SidenavTile label="Add-Ons" isSelected={isSelected('addons')} />
                    </Link>
                  )}
                  {userPermissions?.permissions.includes(USER_PERMISSIONS.extensions) && (
                    <Link
                      onClick={mobileDismissNav}
                      to={buildPathToPageOrGuide({route: ROUTES.extensions, guideRoute: ROUTES.sgAddExtensionsIntro, salonId, guideCompleteOrHasData: extensionsCompleteOrHasData, releaseStartGuideV2})}
                    >
                      <SidenavTile label="Extensions" isSelected={isSelected('extensions')} />
                    </Link>
                  )}
                  {userHasInventoryPermissions(userPermissions) && (
                    <>
                      <Link
                        onClick={mobileDismissNav}
                        to={hasInventoryAndOrders ? buildPathToPageOrGuide({route: ROUTES.inventorySnapshot, guideRoute: ROUTES.sgInventoryIntro, salonId, guideCompleteOrHasData: inventoryCompleteOrHasData, releaseStartGuideV2}) : generatePath(ROUTES.inventoryOrdersPaywall, {salonId})}
                      >
                        <SidenavTile label="Inventory" isSelected={isSelected('inventory')} badgeName="beta" />
                      </Link>
                    </>
                  )}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          ) : (
            <>
              {userPermissions?.permissions.includes(USER_PERMISSIONS.colorLines) && (
                <Link onClick={mobileDismissNav} to={generatePath(ROUTES.lines, { salonId })}>
                  <SidenavTile iconName="products" label="Color Lines" isSelected={isSelected('color-lines')} />
                </Link>
              )}
            </>
          )}

          {releaseLabor && userPermissions?.permissions.includes(USER_PERMISSIONS.labor) && (
              <Link
                onClick={mobileDismissNav}

                to={buildPathToPageOrGuide({route: ROUTES.labor, guideRoute: ROUTES.sgLaborIntro, salonId, guideCompleteOrHasData: laborCompleteOrHasData, releaseStartGuideV2})}
              >
                <SidenavTile iconName='labor' label="Services" isSelected={isSelected('labor')} />
              </Link>
            )}
          {/*{userPermissions?.permissions.includes('sessions') && (*/}
          <Link onClick={mobileDismissNav} to={releaseOpenDay ? generatePath(ROUTES.sessionsByMember, { salonId }) : generatePath(ROUTES.sessions, { salonId })}>
            <SidenavTile iconName="sessions" label="Sessions" isSelected={isSelected('sessions')} />
          </Link>
          {/*)}*/}
          {showTeam && userPermissions?.permissions.includes(USER_PERMISSIONS.stylists) && (
              <Link
                onClick={mobileDismissNav}
                to={buildPathToPageOrGuide({route: ROUTES.stylists, guideRoute: ROUTES.sgAddTeamIntro, salonId, guideCompleteOrHasData: teamCompleteOrHasData, releaseStartGuideV2})}
              >
                <SidenavTile iconName="stylists" label="Team" isSelected={isSelected('stylists')} />
              </Link>
            )}

          {/* reports v1 */}
          {/**/}
          {/**/}
          {/**/}
          {userPermissions?.permissions.includes(USER_PERMISSIONS.reports) && (
            <Link to={generatePath(ROUTES.colorBrandReports, { salonId })}>
              <SidenavTile iconName="products" label="Reports" isSelected={isSelected('reports')} />
            </Link>
          )}
          {/**/}
          {/* reports v1 end */}

          {userPermissions?.permissions.includes(USER_PERMISSIONS.orders) && (
            <Link onClick={mobileDismissNav} to={hasInventoryAndOrders ? generatePath(ROUTES.orders, { salonId }) : generatePath(ROUTES.inventoryOrdersPaywall, { salonId })}>
              <SidenavTile iconName="orders" label="Orders" isSelected={isSelected('orders')} />
            </Link>
          )}
          <Link
            onClick={mobileDismissNav}
            to={buildPathToPageOrGuide({route: ROUTES.clients, guideRoute: ROUTES.sgAddClientsIntro, salonId, guideCompleteOrHasData: clientsCompleteOrHasData, releaseStartGuideV2})}
          >
            <SidenavTile iconName="clients" label="Clients" isSelected={isSelected('clients')} />
          </Link>

          <Link onClick={mobileDismissNav} to={generatePath(ROUTES.tutorials, { salonId })}>
            <SidenavTile iconName="tutorials" label="Tutorials" isSelected={isSelected('tutorials')} />
          </Link>
          {/* todo: set up permissions for this and use permissions instead of roles here */}
          {userPermissions?.permissions.includes(USER_PERMISSIONS.salonGuide) && (
            <Link onClick={mobileDismissNav} to={releaseStartGuideV2 ? generatePath(ROUTES.sgIndex, { salonId }) : generatePath(ROUTES.startGuide, { salonId })}>
              <SidenavTile iconName="startGuide" label="Start Guide" isSelected={isSelected('start-guide')} />
            </Link>
          )}
          {!releaseMultiSalon && userPermissions?.permissions.includes(USER_PERMISSIONS.settings) && (
            <Link onClick={mobileDismissNav} to={generatePath(ROUTES.settings, { salonId })}>
              <SidenavTile iconName="settings" label="Settings" isSelected={isSelected('settings')} />
            </Link>
          )}
        </>
      )}
      <DisableAutofocus />
    </Box>
  )
}


const OwnerSubSidenavLinks = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const releaseLabour = ReleaseLabour()

  const { isMobile, isTablet } = UseViewSize()
  const isSelected = (route: string): boolean => location.pathname.includes(route)
  const params: any = useParams()
  const { salonId } = params

  const mobileDismissNav = () => {
    if (isMobile || isTablet) {
      dispatch(setNavVisible(false))
    }
  }

  return(
    <>
      <Text padding='15px' fontWeight="bold" fontSize="14px">Back Bar & Services</Text>
      <Link onClick={mobileDismissNav} to={generatePath(ROUTES.colorBrandReports, { salonId })}>
        <SidenavTile label="Color Lines" isSelected={isSelected('color-brand-reports') || isSelected('color-brand-used-products-report')} />
      </Link>
      <Link onClick={mobileDismissNav} to={generatePath(ROUTES.allColorBrandUnusedProductReports, { salonId })}>
        <SidenavTile label="Unused Color" isSelected={isSelected('all-color-brand-unused-products-report')} />
      </Link>
      <Link onClick={mobileDismissNav} to={generatePath(ROUTES.allColorLineWasteProductsReports, { salonId })}>
        <SidenavTile label="Color waste" isSelected={isSelected('all-color-line-waste-products-report')} />
      </Link>
      <Link onClick={mobileDismissNav} to={generatePath(ROUTES.addonReports, { salonId })}>
        <SidenavTile label="Addons" isSelected={isSelected('addon-reports')} />
      </Link>
      <Link onClick={mobileDismissNav} to={generatePath(ROUTES.extensionReports, { salonId })}>
        <SidenavTile label="Extensions" isSelected={isSelected('extension-reports')} />
      </Link>
      {releaseLabour && <Link onClick={mobileDismissNav} to={generatePath(ROUTES.colorLaborReports, { salonId })}>
        <SidenavTile label="Services" isSelected={isSelected('color-labor-reports')} />
      </Link>}
      
      <Text padding={'15px'} fontWeight="bold" fontSize="14px">Stylists</Text>
      <Link onClick={mobileDismissNav} to={generatePath(ROUTES.colorStylistReports, { salonId })}>
        <SidenavTile label="Color Lines" isSelected={isSelected('color-stylist-reports') || isSelected('color-stylist-products-reports')} />
      </Link>
      <Link onClick={mobileDismissNav} to={generatePath(ROUTES.colorStylistWasteReports, { salonId })}>
        <SidenavTile label="Color Waste" isSelected={isSelected('color-stylist-waste-reports') || isSelected('color-stylist-waste-products-reports')} />
      </Link>
      <Link onClick={mobileDismissNav} to={generatePath(ROUTES.addonStylistReports, { salonId })}>
        <SidenavTile label="Addons" isSelected={isSelected('addon-stylist-reports')} />
      </Link>
      <Link onClick={mobileDismissNav} to={generatePath(ROUTES.extensionStylistReports, { salonId })}>
        <SidenavTile label="Extensions" isSelected={isSelected('extension-stylist-reports')} />
      </Link>

      <Text padding={'15px'} fontWeight="bold" fontSize="14px">Clients</Text>
      <Link onClick={mobileDismissNav} to={generatePath(ROUTES.colorReportsClient, { salonId })}>
        <SidenavTile label="Color lines" isSelected={isSelected('color-reports/client')} />
      </Link>
      <Link onClick={mobileDismissNav} to={generatePath(ROUTES.addonClientReports, { salonId })}>
        <SidenavTile label="Addons" isSelected={isSelected('addon-client-reports')} />
      </Link>
      <Link onClick={mobileDismissNav} to={generatePath(ROUTES.extensionClientReports, { salonId })}>
        <SidenavTile label="Extensions" isSelected={isSelected('extension-client-reports')} />
      </Link>
    </>
  )
}