import React, { useEffect, useState } from 'react'
import { Box, Button, Flex, Modal, ModalBody, ModalContent, ModalOverlay, Progress, Text } from '@chakra-ui/react'
import { useAppSelector } from '../../../hooks'
import { selectLoggedInUser } from '../../../data/user/slice'
import { dispatchGetUserLara } from '../../../data/user/api'
import { useDispatch } from 'react-redux'
import { getLocalStorageItem } from '../../../core/localStorage'
import { generatePath, Link } from 'react-router-dom'
import { SGIndexPage } from "../../start-guide-v2/SGIndex/SGIndexPage";
import { Gap } from "../../../mini-lib/gap/Gap";
import { UseViewSize } from "../../../core/UseViewSize";
import { COLORS } from "../../../mini-lib/theme/colors";
import { ROUTES } from "../../../appRoutes";
import { dispatchSalonSnapshotSync } from "../../../integrations/pipedrive/api";
import {ReleaseStripe} from "../../../mini-lib/flags/Release";
import {getCurrentPaymentStatus} from "../../../data/user/utils";

export const SignupSubscriptionRedirectPage = () => {
  const dispatch = useDispatch()
  const user = useAppSelector(selectLoggedInUser)
  const localStorageToken = getLocalStorageItem('auth-token')
  const localStorageUserId = getLocalStorageItem('user-id')
  const [time, setTime] = useState(0)
  const [canRedirect, setCanRedirect] = useState(false)
  const {isMobile} = UseViewSize()

  const releaseStripe = ReleaseStripe()
  const salonId = user && user.salonContexts && user.salonContexts.length > 0 ? user.salonContexts[0].salonId : null

  useEffect(() => {
    const timer = setTimeout(() => {
      if (localStorageToken && localStorageUserId) {
        setTime(time + 1)

        if (!canRedirect) {
          dispatch(dispatchGetUserLara({ token: localStorageToken, userId: localStorageUserId }))
        }
      }
    }, 2000)
    return () => clearTimeout(timer)
  }, [dispatch, time, setTime, localStorageUserId, localStorageToken, canRedirect])

  // signup success
  //
  //
  const paymentStatus = user && releaseStripe ? getCurrentPaymentStatus(user) : null

  if (releaseStripe) {
    if (!canRedirect && user && salonId && paymentStatus && paymentStatus.status === 'active') {
      // on successful signup sync user to pipedrive
      dispatch(dispatchSalonSnapshotSync({token: user.token, salonId: salonId, userId: user.userId}))
      //
      // todo: figure out if we want to add a confirmation or something here
      // it feels weird to automatically do this
      // dispatch(dispatchResetPricing({token: user.token, salonId, onlyAutoPriced: true, shouldArchive: false}))
      // allow the user to enter the webapp
      setCanRedirect(true)
    }
  } else {
    if (!canRedirect && user?.currentSalonContext?.salonId && user?.currentSalonContext?.subscription?.isActive && ['active', 'non_renewing'].includes(user?.currentSalonContext?.subscription?.subscriptionStatus)) {
      const salonId = user.currentSalonContext.salonId
      // on successful signup sync user to pipedrive
      dispatch(dispatchSalonSnapshotSync({token: user.token, salonId, userId: user.userId}))
      //
      // dispatch(dispatchResetPricing({token: user.token, salonId, onlyAutoPriced: true, shouldArchive: false}))
      // allow the user to enter the webapp
      setCanRedirect(true)
    }
  }
  //
  //


  const redirectUrl = user && user.currentSalonContext
    ? generatePath(ROUTES.sgIndex, { salonId: user.currentSalonContext.salonId }) : ''
  return (
    <>
      <Modal isCentered={true} isOpen={true} onClose={() => {}}>
        <ModalOverlay/>
        <ModalContent minW={isMobile ? '80%' : '700px'} m='12px' borderRadius='20px' position='relative'>
          <ModalBody>
            <Box position="absolute" top={0} left={0} h="33%" w="100%" bg={COLORS.lavender_gradient} zIndex="1" pointerEvents='none'/>
            <Flex direction='column' justify='center' align='center' p='48px 12px'>

              <Flex justify='center' fontSize='55px'>🎉</Flex>
              <Text variant='largetitle' textTransform='uppercase'>Congrats You're In!</Text>
              <Gap/>
              <Text>An order confirmation and receipt have been sent to your email inbox.</Text>
              <Gap/>
              {canRedirect
                ? <Link to={redirectUrl}>
                  <Button variant='round' colorScheme='brand.midnight'>Let's Get Started</Button>
                </Link>
                : <>
                  <Progress colorScheme="brand.lavender" size="xs" isIndeterminate width="80%"/>
                  <Gap/>
                  <Text textAlign='center' color="brand.lavender.500">Preparing your account... this should only take a second!</Text>
                </>
              }
            </Flex>
          </ModalBody>

        </ModalContent>
      </Modal>

      <SGIndexPage/>
    </>
  )
}
