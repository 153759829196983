import React from 'react'
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom'
import { HomePage } from './pages/home/HomePage'
import { StylistsPage } from './pages/stylists/StylistsPage'
import { ClientsPage } from './pages/clients/ClientsPage'
import { SessionsPage } from './pages/sessions/SessionsPage'
import { LinesPage } from './pages/products/lines/LinesPage'
import { VendorsPage } from './pages/products/vendors/VendorsPage'
import { UserDashboardPage } from './pages/user-dashboard/UserDashboardPage'
import { LegacyLaborPage } from './pages/labor-legacy/LegacyLaborPage'
import { LaborOnboardingPage } from './pages/labor-legacy/onboarding/v1/LaborOnboardingPage'
import { SuppliesPage } from './pages/supplies/SuppliesPage'
import { SuppliesPage as  SuppliesPageV2} from './pages/supplies-v2/SuppliesPage'
import { SuppliesOnboardingPage } from './pages/supplies/SuppliesOnboardingPage'
import { ExtensionsPage } from './pages/extensions/ExtensionsPage'
import { ExtensionsAddPage } from './pages/extensions/ExtensionsAddPage'
import { PlanPage } from './pages/signup/v1/PlanPage'
import { AccessoryPage } from './pages/signup/v1/AccessoryPage'
import { CheckoutPage } from './pages/signup/v1/CheckoutPage'
import { RoutingGuard } from './core/access/RoutingGuard'
import { SettingsPage } from './pages/settings/SettingsPage'
import { TutorialsPage } from './pages/tutorials/TutorialsPage'
import { OrdersPage } from './pages/orders/OrdersPage'
import { OrderDetailPage } from './pages/orders/OrderDetailPage'
import { ReportsPage } from './pages/color-line-reports/ReportsPage'
import { ScheduledMaintenancePage } from './pages/ScheduledMaintenancePage'
import { OrderCreatePage } from './pages/order-create/OrderCreatePage'
import { LoginPage } from './pages/login/LoginPage'
import { ForgotPasswordPage } from './pages/password/ForgotPasswordPage'
import { ResetPasswordPage } from './pages/password/ResetPasswordPage'
import { StartGuidePage } from './pages/start-guide/StartGuidePage'
import { UserRedirectPage } from './pages/user-redirect/UserRedirectPage'
import { SignupSubscriptionRedirectPage } from './pages/signup/v1/SignupSubscriptionRedirectPage'
import { StylistInviteSignupPage } from './pages/stylist-invite/StylistInviteSignupPage'
import { InventoryAddStockPage } from './pages/inventory/add-stock/InventoryAddStockPage'
import { InventoryCountDetailsPage } from './pages/inventory/details/InventoryCountDetailsPage'
import { InventoryStockAlertsPage } from './pages/inventory/stock-alerts/InventoryStockAlertsPage'
import { InventoryAuditsPage } from './pages/inventory/audit/InventoryAuditsPage'
import { useAppSelector } from './hooks'
import { selectLoggedInUserSalonPermissions } from './data/user/slice'
import { USER_PERMISSIONS } from './data/user/constants'
import { ExtensionReportsPage } from './pages/extension-reports/ExtensionReportsPage'
import { AddonReportsPage } from './pages/addon-reports/AddonReportsPage'
import { AdminUpcUpload } from './pages-admin/upcs/AdminUpcUpload'
import { AdminValidateUpcPage } from './pages-admin/upcs/AdminValidateUpcPage'
import { InventorySnapshotPage } from './pages/inventory/snapshot/InventorySnapshotPage'
import { LaborLaborReportsPage } from './pages/labor-reports/labor-labor-reports/LaborLaborReportsPage'
import { ColorLineReportsPage as ColorLineReportsPageV1 } from './pages/reports-v1/ColorLineReportsPage'
import { ColorClientsReportsPage } from './pages/reports-v2/colors/ColorClientsReportsPage'
import { AllColorLineUnusedProductsReportPage } from './pages/reports-v2/colors/AllColorLineUnusedProductsReportPage'
import { ColorLineUsedProductsReportPage } from './pages/reports-v2/colors/ColorLineUsedProductsReportPage'
import { ColorLineUnusedProductsReportPage } from './pages/reports-v2/colors/ColorLineUnusedProductsReportPage'
import { ColorLineWasteProductsReportPage } from './pages/reports-v2/colors/ColorLineWasteProductsReportPage'
import { AllColorLineWasteProductsReportPage } from './pages/reports-v2/colors/AllColorLineWasteProductsReportPage'

import { ColorStylistReportsPage as ColorStylistReportsPageV1 } from './pages/reports-v1/ColorStylistReportsPage'
import { ColorStylistsWasteReportsPage } from './pages/reports-v2/colors/ColorStylistsWasteReportsPage'
import { ColorStylistProductReportsPage } from './pages/reports-v2/colors/ColorStylistProductReportsPage'
import { ColorStylistWasteProductReportsPage } from './pages/reports-v2/colors/ColorStylistWasteProductReportsPage'

import { COLOR_REPORTS_TABS } from './pages/reports-v1/ColorReportsTabs'
import { GrinPage } from './integrations/grin/GrinPage'
import { UserSettingsPage } from './pages/settings/UserSettingsPage'
import { SalonSettingsPage } from './pages/settings/SalonSettingsPage'
import { PermissionSettingsPage } from './pages/settings/PermissionSettingsPage'
import { LogoutPage } from './pages/login/LogoutPage'
import { ExistingStylistSalonJoinRedirect } from './pages/stylist-invite/ExistingStylistSalonJoinRedirect'
import { AddLinesV2Page } from './pages/products/add-lines-v2/AddLinesV2Page'
import { SGIntroPage } from './pages/start-guide-v2/SGIntroPage'
import { SGAddLinesIntroPage } from './pages/start-guide-v2/SGAddLinesIntroPage'
import { OwnerSidenav } from './mini-lib/sidenav/OwnerSidenav'
import { AdminSidenav } from './mini-lib/sidenav/AdminSidenav'
import { MockStartGuideSidenavLinks, StartGuideSidenav } from './pages/start-guide-v2/StartGuideSidenav'
import { COLORS } from './mini-lib/theme/colors'
import { Box } from '@chakra-ui/react'
import { SGPricingIntroPage } from './pages/start-guide-v2/SGPricingIntroPage'
import { SGIndexPage } from './pages/start-guide-v2/SGIndex/SGIndexPage'
import { ClientsBulkUploadPage } from './pages/clients/ClientsBulkUploadPage'
import { SGTeamIntroPage } from './pages/start-guide-v2/SGTeamIntroPage'
import { SGClientsIntroPage } from './pages/start-guide-v2/SGClientsIntroPage'
import { AdminPage } from './pages-admin/AdminPage'
import { SGInventoryIntroPage } from './pages/start-guide-v2/SGInventoryIntroPage'
import { SGInventoryTargetsIntroPage } from './pages/start-guide-v2/SGInventoryTargetsIntroPage'
import { SGInventoryOnHandIntroPage } from './pages/start-guide-v2/SGInventoryOnHandIntroPage'
import { SGInventoryAlertsIntroPage } from './pages/start-guide-v2/SGInventoryAlertsIntroPage'
import { SGInventoryOrdersIntroPage } from './pages/start-guide-v2/SGInventoryOrdersIntroPage'
import { SGInventoryAddingStockIntroPage } from './pages/start-guide-v2/SGInventoryAddingStockIntroPage'
import { ProductTargetPage } from './pages/products/lines/ProductTargetPage'
import { ProductOnHandPage } from './pages/products/lines/ProductOnHandPage'
import { SGAddonsIntroPage } from './pages/start-guide-v2/SGAddonsIntroPage'
import { SGExtensionsIntroPage } from './pages/start-guide-v2/SGExtensionsIntroPage'
import { SGSessionsIntroPage } from './pages/start-guide-v2/SGSessionsIntroPage'
import { SGAcademyIntroPage } from './pages/start-guide-v2/SGAcademyIntroPage'
import { RedirectWithSalonContext } from './mini-lib/router/RedirectWithSalonContext'
import { RouteNotFoundRedirectPage } from './pages/user-redirect/RouteNotFoundRedirectPage'
import { AdminDataResetPage } from './pages-admin/data-reset/AdminDataResetPage'
import { NotificationPage } from './pages/notifications/NotificationPage'
import { CancellationPolicyPage } from './pages/policies/CancellationPolicyPage'
import { RefundPolicyPage } from './pages/policies/RefundPolicyPage'
import { SessionDetailsPage } from './pages/session-details/SessionDetailsPage'
import { MinilibFormCodeExamplesPage } from './mini-lib/form/MinilibFormCodeExamplesPage'
import { AddExtensionsV2Page } from './pages/extensions/add-extensions-v2/AddExtensionsV2Page'
import { SquareOAuthCallbackPage } from './pages/square/SquareOAuthCallbackPage'
import { IntegrationSettingsPage } from './pages/settings/IntegrationSettingsPage'
import { AdminSubscriptionsAnalytics } from './pages-admin/analytics/AdminSubscriptionsAnalytics'
import { AdminUsersAnalytics } from './pages-admin/analytics/AdminUsersAnalytics'
import { SignupCreateUserPage } from './pages/signup/v2/SignupCreateUserPage'
import { SignupCreateSalonPage } from './pages/signup/v2/SignupCreateSalonPage'
import { SignupSalonDescriptionPage } from './pages/signup/v2/SignupSalonDescriptionPage'
import { SignupAddColorsPage } from './pages/signup/v2/SignupAddColorsPage'
import { TrialIndexPage } from './pages/trial-v1/TrialIndexPage'
import { CheckoutSubscriptionPage } from './pages/checkout/v1/CheckoutSubscriptionPage'
import { CheckoutFreeScalesPage } from './pages/checkout/v1/CheckoutFreeScalesPage'
import { CheckoutCartPage } from './pages/checkout/v1/CheckoutCartPage'
import { ROUTES } from './appRoutes'
import { SGPOSIntroPage } from "./pages/start-guide-v2/SGPOSIntroPage";
import { SquareConnectTeamPage } from "./pages/square/SquareConnectTeamPage";
import { SetInitialPasswordPage } from "./pages/password/SetInitialPasswordPage";
import { SquareConnectAuthorization } from "./pages/square/SquareConnectAuthorization";
import { MinilibKitchenSinkPage } from "./mini-lib/form/MinilibKitchenSinkPage";
import { SessionsBySalonMemberPage } from "./pages/sessions/SessionsBySalonMemberPage";
import { SignupTrialOrPayPage } from "./pages/signup/v2/SignupTrialOrPayPage";
import { SignupTrialSuccessRedirect } from './pages/signup/v2/SignupTrialSuccessRedirect'
import { tabs } from './pages/reports-v2/utils'
import { SGLaborIntroPage } from './pages/start-guide-v2/SGLaborIntroPage'
import { LaborSoloOnboardingPage } from "./pages/labor-legacy/onboarding/v2/LaborSoloOnboardingPage";
import { LaborTeamOnboardingPage } from "./pages/labor-legacy/onboarding/v2/LaborTeamOnboardingPage";
import {AdminMasterProductsPage} from "./pages-admin/master-products/MasterColorPage";
import { BlackFridayPage } from './pages/black-friday/BlackFridayPage'
import {CheckoutPaidScalesPage} from "./pages/checkout/v1/CheckoutPaidScalesPage";
import {AdminBulkUploadMasterProductsPage} from "./pages-admin/master-products/AdminBulkUploadMasterProductsPage";
import {SignupSalonProblemsPage} from "./pages/signup/v2/SignupSalonProblemsPage";
import {SignupSalonPartsAndLaborPage} from "./pages/signup/v2/SignupSalonPartsAndLaborPage";
import {LaborPage} from "./pages/labor/LaborPage";
import {CheckoutV2SubscriptionPage} from "./pages/checkout/v2/CheckoutV2SubscriptionPage";
import {ManagePaymentPage} from "./pages/payment/ManagePaymentPage";
import { ColorLaborReportsPage } from './pages/reports-v2/colors/ColorLaborReportsPage'
import {InventoryOrderPaywallPage} from "./pages/payment/InventoryOrderPaywallPage";
import {RedirectPage} from './pages/RedirectPage'

export default function AppRouter() {
  return (
    <Router>
      <Switch>
        {/* unauthed pages */}
        {/**/}
        {/**/}

        {/* note: to override all pages just remove uncomment the line below and make sure it's above all other routes*/}
        {/* <Route component={ScheduledMaintenancePage} /> */}
        <Route exact path={ROUTES.login}>
          <RoutingGuard>
            <LoginPage />
          </RoutingGuard>
        </Route>

        <Route exact path={ROUTES.minilibForms}>
          <MinilibFormCodeExamplesPage />
        </Route>
        <Route exact path={ROUTES.minilibKitchenSink}>
          <MinilibKitchenSinkPage />
        </Route>

        <Route exact path={ROUTES.logout}>
          <LogoutPage />
        </Route>
        <Route path={ROUTES.blackFriday}>
          <RoutingGuard>
            <BlackFridayPage/>
          </RoutingGuard>
        </Route>
        <Route path={ROUTES.userSettingsNoNav}>
          <RoutingGuard requireAuth={true} requireSalon={true}>
            <UserSettingsPage/>
          </RoutingGuard>
        </Route>

        <Route exact path={ROUTES.cancellationPolicy}>
          <CancellationPolicyPage />
        </Route>
        <Route exact path={ROUTES.refundPolicy}>
          <RefundPolicyPage />
        </Route>
        <Route exact path={ROUTES.stylistInviteSignup}>
          <StylistInviteSignupPage />
        </Route>
        <Route exact path={ROUTES.existingStylistSalonJoinRedirect}>
          <ExistingStylistSalonJoinRedirect />
        </Route>
        <Route path={ROUTES.forgotPassword}>
          <ForgotPasswordPage />
        </Route>
        <Route path={ROUTES.resetPassword}>
          <ResetPasswordPage />
        </Route>
        <Route exact path={ROUTES.redirect}>
          <RoutingGuard>
            <RedirectPage />
          </RoutingGuard>
        </Route>

        {/* signup v2 routes start */}
        {/**/}
        {/**/}
        <Route path={ROUTES.signupV2Type}>
          <SignupTrialOrPayPage />
        </Route>
        <Route path={ROUTES.signupV2User}>
          <SignupCreateUserPage />
        </Route>
        <Route path={ROUTES.signupV2Salon}>
          <RoutingGuard requireAuth={true}>
            <SignupCreateSalonPage />
          </RoutingGuard>
        </Route>
        <Route path={ROUTES.signupV2Description}>
          <RoutingGuard requireAuth={true}>
            <SignupSalonDescriptionPage />
          </RoutingGuard>
        </Route>
        <Route path={ROUTES.signupV2Problems}>
          <RoutingGuard requireAuth={true}>
            <SignupSalonProblemsPage />
          </RoutingGuard>
        </Route>
        <Route path={ROUTES.signupV2PartsAndLabor}>
          <RoutingGuard requireAuth={true}>
            <SignupSalonPartsAndLaborPage />
          </RoutingGuard>
        </Route>
        <Route path={ROUTES.signupV2Colors}>
          <RoutingGuard requireAuth={true}>
            <SignupAddColorsPage />
          </RoutingGuard>
        </Route>
        <Route path={ROUTES.signupV2SuccessTrial}>
          <RoutingGuard requireAuth={true}>
            <SignupTrialSuccessRedirect />
          </RoutingGuard>
        </Route>
        <Route path={ROUTES.setInitialPassword}>
          <RoutingGuard
            requireAuth={true}
            requireActiveSubscription={true}
            requireSalon={true}
          >
            <SetInitialPasswordPage/>
          </RoutingGuard>
        </Route>
        {/**/}
        {/**/}
        {/* signup v2 routes end*/}

        {/* signup v1 routes*/}
        {/**/}
        {/**/}
        <Route path={ROUTES.signupPlans}>
          <Redirect to={ROUTES.signupV2User + '?type=trial'}/>
          <PlanPage />
        </Route>
        <Route path={ROUTES.signupAccessories}>
          <AccessoryPage />
        </Route>
        <Route path={ROUTES.signupCheckout}>
          <CheckoutPage />
        </Route>

        <Route path={ROUTES.signupSubscriptionRedirect}>
          <MockStartGuideSidenavLinks>
            <Box
              position="absolute"
              top={0}
              left={0}
              h="350px"
              w="100vw"
              bg={COLORS.lavender_gradient}
              zIndex="1"
              pointerEvents="none"
            />
            <RoutingGuard requireAuth={true}>
              <SignupSubscriptionRedirectPage />
            </RoutingGuard>
          </MockStartGuideSidenavLinks>
        </Route>

        <Route path={ROUTES.scheduledMaintenance}>
          <ScheduledMaintenancePage />
        </Route>
        <Route path={ROUTES.squareOAuthCallback}>
          <RoutingGuard>
            <SquareOAuthCallbackPage />
          </RoutingGuard>
        </Route>
        <Route exact path={ROUTES.adminNotification}>
          <RoutingGuard requireAuth={true} requiredRole={false} requireAdmin={true}>
            <AdminSidenav>
              <AdminPage />
            </AdminSidenav>
          </RoutingGuard>
        </Route>
        <Route exact path={ROUTES.adminValidateUpc}>
          <RoutingGuard requireAuth={true} requiredRole={false} requireAdmin={true}>
            <AdminSidenav>
              <AdminValidateUpcPage />
            </AdminSidenav>
          </RoutingGuard>
        </Route>
        <Route exact path="/">
          <UserRedirectPage />
        </Route>

        {/* authed pages */}
        {/**/}
        {/**/}

        <Route path={ROUTES.userDashboard}>
          <RoutingGuard requireAuth={true}>
            <UserDashboardPage />
          </RoutingGuard>
        </Route>

        <Route path={ROUTES.baseTrial}>
          <TrialRoutes />
        </Route>

        <Route path={ROUTES.baseCheckoutV2}>
          <CheckoutV2Routes />
        </Route>

        <Route path={ROUTES.baseCheckoutV1}>
          <CheckoutV1Routes />
        </Route>

        <Route path={ROUTES.baseStartGuide}>
          <StartGuideRoutes />
        </Route>

        <Route path={ROUTES.baseSalon}>
          <SalonRoutes />
        </Route>

        {/* admin routes */}
        <Route path={ROUTES.adminWebinarDataReset}>
          <RoutingGuard requireAdmin={true} requireAuth={true}>
            <AdminDataResetPage />
          </RoutingGuard>
        </Route>
        <Route path={ROUTES.adminDataReset}>
          <RoutingGuard requireAdmin={true} requireAuth={true}>
            <AdminDataResetPage />
          </RoutingGuard>
        </Route>
        <Route path={ROUTES.adminMasterProducts}>
          <RoutingGuard requireAdmin={true} requireAuth={true}>
            <AdminMasterProductsPage />
          </RoutingGuard>
        </Route>
        <Route path={ROUTES.adminBulkUploadMasterProducts}>
          <RoutingGuard requireAdmin={true} requireAuth={true}>
            <AdminBulkUploadMasterProductsPage />
          </RoutingGuard>
        </Route>
        <Route path={ROUTES.adminAnalyticsDashboard}>
          <RoutingGuard requireAdmin={true} requireAuth={true}>
            <AdminSubscriptionsAnalytics />
          </RoutingGuard>
        </Route>
        <Route path={ROUTES.adminUsersAnalytics}>
          <RoutingGuard requireAdmin={true} requireAuth={true}>
            <AdminUsersAnalytics />
          </RoutingGuard>
        </Route>
        <Route path={ROUTES.adminUpcUpload}>
          <RoutingGuard requireAdmin={true} requireAuth={true}>
            <AdminUpcUpload />
          </RoutingGuard>
        </Route>
        <Route path={ROUTES.adminGrinIntegration}>
          <RoutingGuard requireAdmin={true} requireAuth={true}>
            <GrinPage />
          </RoutingGuard>
        </Route>

        {/* Redirect any /guide/* route */}
        <Route
          path="/guide*"
          render={({ match }) => {
            return <RedirectWithSalonContext match={match} />
          }}
        />
        <Route
          path="/start-guide*"
          render={({ match }) => {
            return <RedirectWithSalonContext match={match} />
          }}
        />
        {/* if they have matched no routes redirect them to a different page */}
        {/* todo: potentially redirect them to an interim not found page */}
        <Route path="*">
          <RouteNotFoundRedirectPage />
        </Route>
      </Switch>
    </Router>
  )
}

export const SalonRoutes = () => {
  const userPermissions = useAppSelector(selectLoggedInUserSalonPermissions)
  return (
    <OwnerSidenav>
      <Route path={ROUTES.home}>
        <RoutingGuard
          requireAuth={true}
          requireActiveSubscription={true}
          requireSalon={true}
          requiredRole={userPermissions?.permissions.includes(USER_PERMISSIONS.home)}
        >
          <HomePage/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.vendors}>
        <RoutingGuard
          requireAuth={true}
          requireActiveSubscription={true}
          requireSalon={true}
          requiredRole={userPermissions?.permissions.includes(USER_PERMISSIONS.colorLines)}
        >
          <VendorsPage/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.laborOnboard}>
        <RoutingGuard requireAuth={true} requireActiveSubscription={true} requireSalon={true}>
          <LaborOnboardingPage/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.laborSoloOnboard}>
        <RoutingGuard requireAuth={true} requireSalon={true}>
          <LaborSoloOnboardingPage/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.laborTeamOnboard}>
        <RoutingGuard requireAuth={true} requireSalon={true}>
          <LaborTeamOnboardingPage/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.legacyLabor}>
        <RoutingGuard requireAuth={true} requireActiveSubscription={true} requireSalon={true}>
          <LegacyLaborPage/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.labor}>
        <RoutingGuard requireAuth={true} requireActiveSubscription={true} requireSalon={true}>
          <LaborPage/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.supplies}>
        <RoutingGuard
          requireAuth={true}
          requireActiveSubscription={true}
          requireSalon={true}
          requiredRole={userPermissions?.permissions.includes(USER_PERMISSIONS.addons)}
        >
          <SuppliesPage/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.suppliesV2}>
        <RoutingGuard
          requireAuth={true}
          requireActiveSubscription={true}
          requireSalon={true}
          requiredRole={userPermissions?.permissions.includes(USER_PERMISSIONS.addons)}
        >
          <SuppliesPageV2/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.supplyOnboard}>
        <RoutingGuard
          requireAuth={true}
          requireActiveSubscription={true}
          requireSalon={true}
          requiredRole={userPermissions?.permissions.includes(USER_PERMISSIONS.addons)}
        >
          <SuppliesOnboardingPage/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.extensions}>
        <RoutingGuard
          requireAuth={true}
          requireActiveSubscription={true}
          requireSalon={true}
          requiredRole={userPermissions?.permissions.includes(USER_PERMISSIONS.extensions)}
        >
          <ExtensionsPage/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.extensionsAdd}>
        <RoutingGuard
          requireAuth={true}
          requireActiveSubscription={true}
          requireSalon={true}
          requiredRole={userPermissions?.permissions.includes(USER_PERMISSIONS.extensions)}
        >
          <ExtensionsAddPage/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.addExtensionsV2}>
        <RoutingGuard
          requireAuth={true}
          requireActiveSubscription={true}
          requireSalon={true}
          requiredRole={userPermissions?.permissions.includes(USER_PERMISSIONS.extensions)}
        >
          <AddExtensionsV2Page/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.lines}>
        <RoutingGuard
          requireAuth={true}
          requireActiveSubscription={true}
          requireSalon={true}
          requiredRole={userPermissions?.permissions.includes(USER_PERMISSIONS.colorLines)}
        >
          <LinesPage/>
        </RoutingGuard>
      </Route>

      <Route path={ROUTES.inventorySnapshot}>
        <RoutingGuard
          requiredRole={userPermissions?.permissions.includes(USER_PERMISSIONS.inventorySnapshot)}
          requireAuth={true}
          requireActiveSubscription={true}
          requireSalon={true}
        >
          <InventorySnapshotPage/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.inventoryAudits}>
        <RoutingGuard
          requiredRole={userPermissions?.permissions.includes(USER_PERMISSIONS.inventoryAudit)}
          requireAuth={true}
          requireActiveSubscription={true}
          requireSalon={true}
        >
          <InventoryAuditsPage/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.inventoryAddStock}>
        <RoutingGuard
          requiredRole={userPermissions?.permissions.includes(USER_PERMISSIONS.inventoryAddStock)}
          requireAuth={true}
          requireActiveSubscription={true}
          requireSalon={true}
        >
          <InventoryAddStockPage/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.inventoryStockAlerts}>
        <RoutingGuard
          requiredRole={userPermissions?.permissions.includes(USER_PERMISSIONS.inventoryStockAlerts)}
          requireAuth={true}
          requireActiveSubscription={true}
          requireSalon={true}
        >
          <InventoryStockAlertsPage/>
        </RoutingGuard>
      </Route>

      <Route path={ROUTES.inventoryCount}>
        <RoutingGuard
          requiredRole={userPermissions?.permissions.includes(USER_PERMISSIONS.colorLines)}
          requireAuth={true}
          requireActiveSubscription={true}
          requireSalon={true}
        >
          <InventoryCountDetailsPage/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.addLinesV2}>
        <RoutingGuard
          requireAuth={true}
          requireActiveSubscription={true}
          requireSalon={true}
          requiredRole={userPermissions?.permissions.includes(USER_PERMISSIONS.colorLines)}
        >
          <AddLinesV2Page/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.sessions}>
        <RoutingGuard requireAuth={true} requireActiveSubscription={true} requireSalon={true}>
          <SessionsPage/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.sessionsByMember}>
        <RoutingGuard requireAuth={true} requireActiveSubscription={true} requireSalon={true}>
          <SessionsBySalonMemberPage/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.sessionDetails}>
        <RoutingGuard requireAuth={true} requireActiveSubscription={true} requireSalon={true}>
          <SessionDetailsPage/>
        </RoutingGuard>
      </Route>

      <Route path={ROUTES.sessionDetailsV2}>
        <RoutingGuard requireAuth={true} requireActiveSubscription={true} requireSalon={true}>
          <SessionDetailsPage/>
        </RoutingGuard>
      </Route>
      {/* todo-end: delete after sessions-lara-web fully released */}


      {/* todo-reports: remove this after reports v2 has been released and is stable */}
      {/* reports v1 */}
      {/**/}
      {/**/}
      {/**/}
      <Route path={ROUTES.colorBrandReports}>
        <RoutingGuard
          requireAuth={true}
          requireActiveSubscription={true}
          requireSalon={true}
          requiredRole={userPermissions?.permissions.includes(USER_PERMISSIONS.reports)}
        >
          <ColorLineReportsPageV1 selectedTab={COLOR_REPORTS_TABS.colorBrand}/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.colorReportsStylist}>
        <RoutingGuard
          requireAuth={true}
          requireActiveSubscription={true}
          requireSalon={true}
          requiredRole={userPermissions?.permissions.includes(USER_PERMISSIONS.reports)}
        >
          <ReportsPage selectedTab={1}/>
        </RoutingGuard>
      </Route>

      <Route path={ROUTES.colorStylistReports}>
        <RoutingGuard
          requireAuth={true}
          requireActiveSubscription={true}
          requireSalon={true}
          requiredRole={userPermissions?.permissions.includes(USER_PERMISSIONS.reports)}
        >
          <ColorStylistReportsPageV1 selectedTab={COLOR_REPORTS_TABS.colorStylist}/>
        </RoutingGuard>
      </Route>

      <Route path={ROUTES.colorStylistWasteReports}>
        <RoutingGuard
          requireAuth={true}
          requireActiveSubscription={true}
          requireSalon={true}
          requiredRole={userPermissions?.permissions.includes(USER_PERMISSIONS.reports)}
        >
          <ColorStylistsWasteReportsPage selectedTab={COLOR_REPORTS_TABS.colorStylist}/>
        </RoutingGuard>
      </Route>

      <Route path={ROUTES.colorReportsClient}>
        <RoutingGuard
          requireAuth={true}
          requireActiveSubscription={true}
          requireSalon={true}
          requiredRole={userPermissions?.permissions.includes(USER_PERMISSIONS.reports)}
        >
          <ReportsPage selectedTab={2}/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.extensionReports}>
        <RoutingGuard
          requireAuth={true}
          requireActiveSubscription={true}
          requireSalon={true}
          requiredRole={userPermissions?.permissions.includes(USER_PERMISSIONS.reports)}
        >
          <ExtensionReportsPage selectedTab={tabs.extension}/>
        </RoutingGuard>
      </Route>
        <Route path={ROUTES.extensionStylistReports}>
        <RoutingGuard
          requireAuth={true}
          requireActiveSubscription={true}
          requireSalon={true}
          requiredRole={userPermissions?.permissions.includes(USER_PERMISSIONS.reports)}
        >
          <ExtensionReportsPage selectedTab={tabs.stylist}/>
        </RoutingGuard>
        </Route>
        <Route path={ROUTES.extensionClientReports}>
        <RoutingGuard
          requireAuth={true}
          requireActiveSubscription={true}
          requireSalon={true}
          requiredRole={userPermissions?.permissions.includes(USER_PERMISSIONS.reports)}
        >
          <ExtensionReportsPage selectedTab={tabs.client}/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.addonReports}>
        <RoutingGuard
          requireAuth={true}
          requireActiveSubscription={true}
          requireSalon={true}
          requiredRole={userPermissions?.permissions.includes(USER_PERMISSIONS.reports)}
        >
          <AddonReportsPage selectedTab={tabs.addon} />
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.addonStylistReports}>
        <RoutingGuard
          requireAuth={true}
          requireActiveSubscription={true}
          requireSalon={true}
          requiredRole={userPermissions?.permissions.includes(USER_PERMISSIONS.reports)}
        >
          <AddonReportsPage selectedTab={tabs.stylist} />
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.addonClientReports}>
        <RoutingGuard
          requireAuth={true}
          requireActiveSubscription={true}
          requireSalon={true}
          requiredRole={userPermissions?.permissions.includes(USER_PERMISSIONS.reports)}
        >
          <AddonReportsPage selectedTab={tabs.client} />
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.laborLaborReports}>
        <RoutingGuard
          requireAuth={true}
          requireActiveSubscription={true}
          requireSalon={true}
          requiredRole={userPermissions?.permissions.includes(USER_PERMISSIONS.reports)}
        >
          <LaborLaborReportsPage/>
        </RoutingGuard>
      </Route>
      {/**/}
      {/* end v1 */}

      {/* reports v2 */}
      {/**/}
      {/**/}
      {/**/}
      {/* <Route path={ROUTES.colorBrandReports}>
          <RoutingGuard
            requireAuth={true}
            requireActiveSubscription={true}
            requireSalon={true}
            requiredRole={userPermissions?.permissions.includes(USER_PERMISSIONS.reports)}
          >
            <ColorLineReportsPage />
          </RoutingGuard>
        </Route> */}
      <Route path={ROUTES.colorBrandUsedProductReports}>
        <RoutingGuard
          requireAuth={true}
          requireActiveSubscription={true}
          requireSalon={true}
          requiredRole={userPermissions?.permissions.includes(USER_PERMISSIONS.reports)}
        >
          <ColorLineUsedProductsReportPage/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.colorLineWasteProductsReports}>
        <RoutingGuard
          requireAuth={true}
          requireActiveSubscription={true}
          requireSalon={true}
          requiredRole={userPermissions?.permissions.includes(USER_PERMISSIONS.reports)}
        >
          <ColorLineWasteProductsReportPage/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.allColorLineWasteProductsReports}>
        <RoutingGuard
          requireAuth={true}
          requireActiveSubscription={true}
          requireSalon={true}
          requiredRole={userPermissions?.permissions.includes(USER_PERMISSIONS.reports)}
        >
          <AllColorLineWasteProductsReportPage/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.colorBrandUnusedProductReports}>
        <RoutingGuard
          requireAuth={true}
          requireActiveSubscription={true}
          requireSalon={true}
          requiredRole={userPermissions?.permissions.includes(USER_PERMISSIONS.reports)}
        >
          <ColorLineUnusedProductsReportPage/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.allColorBrandUnusedProductReports}>
        <RoutingGuard
          requireAuth={true}
          requireActiveSubscription={true}
          requireSalon={true}
          requiredRole={userPermissions?.permissions.includes(USER_PERMISSIONS.reports)}
        >
          <AllColorLineUnusedProductsReportPage/>
        </RoutingGuard>
      </Route>
      {/* <Route path={ROUTES.colorStylistReports}>
        <RoutingGuard
          requireAuth={true}
          requireActiveSubscription={true}
          requireSalon={true}
          requiredRole={userPermissions?.permissions.includes(USER_PERMISSIONS.reports)}
        >
          <ColorStylistsReportsPage />
        </RoutingGuard>
      </Route> */}
      <Route path={ROUTES.colorStylistProductsReports}>
        <RoutingGuard
          requireAuth={true}
          requireActiveSubscription={true}
          requireSalon={true}
          requiredRole={userPermissions?.permissions.includes(USER_PERMISSIONS.reports)}
        >
          <ColorStylistProductReportsPage/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.colorStylistWasteProductsReports}>
        <RoutingGuard
          requireAuth={true}
          requireActiveSubscription={true}
          requireSalon={true}
          requiredRole={userPermissions?.permissions.includes(USER_PERMISSIONS.reports)}
        >
          <ColorStylistWasteProductReportsPage/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.colorClientReports}>
        <RoutingGuard
          requireAuth={true}
          requireActiveSubscription={true}
          requireSalon={true}
          requiredRole={userPermissions?.permissions.includes(USER_PERMISSIONS.reports)}
        >
          <ColorClientsReportsPage/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.colorLaborReports}>
        <RoutingGuard
          requireAuth={true}
          requireActiveSubscription={true}
          requireSalon={true}
          requiredRole={userPermissions?.permissions.includes(USER_PERMISSIONS.reports)}
        >
          <ColorLaborReportsPage/>
        </RoutingGuard>
      </Route>
      {/**/}
      {/* end v2*/}
      <Route path={ROUTES.orders}>
        <RoutingGuard
          requireAuth={true}
          requireActiveSubscription={true}
          requireSalon={true}
          requiredRole={userPermissions?.permissions.includes(USER_PERMISSIONS.orders)}
        >
          <OrdersPage/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.orderDetails}>
        <RoutingGuard
          requireAuth={true}
          requireActiveSubscription={true}
          requireSalon={true}
          requiredRole={userPermissions?.permissions.includes(USER_PERMISSIONS.orders)}
        >
          <OrderDetailPage/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.ordersCreate}>
        <RoutingGuard
          requireAuth={true}
          requireActiveSubscription={true}
          requireSalon={true}
          requiredRole={userPermissions?.permissions.includes(USER_PERMISSIONS.orders)}
        >
          <OrderCreatePage/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.clients}>
        <RoutingGuard requireAuth={true} requireActiveSubscription={true} requireSalon={true}>
          <ClientsPage/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.clientsBulkUpload}>
        <RoutingGuard requireAuth={true} requireSalon={true}>
          <ClientsBulkUploadPage />
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.tutorials}>
        <RoutingGuard requireAuth={true} requireActiveSubscription={true} requireSalon={true}>
          <TutorialsPage/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.settings}>
        <RoutingGuard
          requireAuth={true}
          requireSalon={true}
          requiredRole={userPermissions?.permissions.includes(USER_PERMISSIONS.settings)}
        >
          <SettingsPage/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.managePayment}>
        <RoutingGuard
          requireAuth={true}
          requireSalon={true}
          requiredRole={userPermissions?.permissions.includes(USER_PERMISSIONS.settings)}
        >
          <ManagePaymentPage/>
        </RoutingGuard>
      </Route>

      <Route path={ROUTES.userSettings}>
        <RoutingGuard requireAuth={true} requireSalon={true}>
          <UserSettingsPage/>
        </RoutingGuard>
      </Route>

      <Route path={ROUTES.salonSettings}>
        <RoutingGuard
          requireAuth={true}
          requireSalon={true}
          requiredRole={userPermissions?.permissions.includes(USER_PERMISSIONS.settings)}
        >
          <SalonSettingsPage/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.permissionSettings}>
        <RoutingGuard
          requireAuth={true}
          requireActiveSubscription={true}
          requireSalon={true}
          requiredRole={userPermissions?.permissions.includes(USER_PERMISSIONS.settings)}
        >
          <PermissionSettingsPage/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.integrationSettings}>
        <RoutingGuard
          requireAuth={true}
          requireActiveSubscription={true}
          requireSalon={true}
          requiredRole={userPermissions?.permissions.includes(USER_PERMISSIONS.settings)}
        >
          <IntegrationSettingsPage/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.squareConnectTeam}>
        <RoutingGuard
          requireAuth={true}
          requireActiveSubscription={true}
          requireSalon={true}
          requiredRole={userPermissions?.permissions.includes(USER_PERMISSIONS.settings)}
        >
          <SquareConnectTeamPage/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.squareConnectAuthorization}>
        <RoutingGuard
          requireAuth={true}
          requireActiveSubscription={true}
          requireSalon={true}
          requiredRole={userPermissions?.permissions.includes(USER_PERMISSIONS.settings)}
        >
          <SquareConnectAuthorization/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.stylists}>
        <RoutingGuard
          requiredRole={userPermissions?.permissions.includes(USER_PERMISSIONS.stylists)}
          requireAuth={true}
          requireActiveSubscription={true}
          requireSalon={true}
        >
          <StylistsPage/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.startGuide}>
        <RoutingGuard requireAuth={true} requireActiveSubscription={true} requireSalon={true}>
          <StartGuidePage/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.startGuideV2}>
        <RoutingGuard requireAuth={true} requireActiveSubscription={true} requireSalon={true}>
          <SGIndexPage/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.inventoryOrdersPaywall}>
        <RoutingGuard requireAuth={true} requireActiveSubscription={true} requireSalon={true}>
          <InventoryOrderPaywallPage/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.notifications}>
        <RoutingGuard requireAuth={true} requireActiveSubscription={true} requireSalon={true}>
          <NotificationPage />
        </RoutingGuard>
      </Route>
    </OwnerSidenav>
  )
}

export const TrialRoutes = () => {
  return (
    <>
      <Box position="absolute" top={0} left={0} h="350px" w="100vw" bg={COLORS.lavender_gradient} zIndex="1" pointerEvents='none'/>
      <Route path={ROUTES.trialIndex}>
        <RoutingGuard requireAuth={true} requireSalon={true}>
          <TrialIndexPage/>
        </RoutingGuard>
      </Route>

      <Route path={ROUTES.trialSalonSettings}>
        <RoutingGuard requireAuth={true} requireSalon={true}>
          <SalonSettingsPage/>
        </RoutingGuard>
      </Route>

      {/* catch any weird trial routes and send them to the base page*/}
      <Route exact path={ROUTES.baseTrial}>
        <RoutingGuard requireAuth={true} requireSalon={true}>
          <TrialIndexPage/>
        </RoutingGuard>
      </Route>
    </>
  )
}

export const CheckoutV2Routes = () => {
  return (
    <>
      <Box position="absolute" top={0} left={0} h="350px" w="100vw" bg={COLORS.lavender_gradient} zIndex="1" pointerEvents='none'/>
      <Route path={ROUTES.checkoutV2Subscription}>
        <RoutingGuard requireAuth={true} requireSalon={true}>
          <CheckoutV2SubscriptionPage/>
        </RoutingGuard>
      </Route>
    </>
  )
}


export const CheckoutV1Routes = () => {
  return (
    <>
      <Box position="absolute" top={0} left={0} h="350px" w="100vw" bg={COLORS.lavender_gradient} zIndex="1" pointerEvents='none'/>
      <Route path={ROUTES.checkoutV1Subscription}>
        <RoutingGuard requireAuth={true} requireSalon={true}>
          <CheckoutSubscriptionPage/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.checkoutV1FreeScale}>
        <RoutingGuard requireAuth={true} requireSalon={true}>
          <CheckoutFreeScalesPage/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.checkoutV1PaidScale}>
        <RoutingGuard requireAuth={true} requireSalon={true}>
          <CheckoutPaidScalesPage/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.checkoutV1Cart}>
        <RoutingGuard requireAuth={true} requireSalon={true}>
          <CheckoutCartPage/>
        </RoutingGuard>
      </Route>

      {/* catch any weird trial routes and send them to the base page*/}
      <Route exact path={ROUTES.baseTrial}>
        <RoutingGuard requireAuth={true} requireSalon={true}>
          <TrialIndexPage/>
        </RoutingGuard>
      </Route>
    </>
  )
}
export const StartGuideRoutes = () => {
  return (
    <StartGuideSidenav>
      <Box position="absolute" top={0} left={0} h="350px" w="100vw" bg={COLORS.lavender_gradient} zIndex="1" pointerEvents='none'/>
      <Route path={ROUTES.sgIntro}>
        <RoutingGuard requireAuth={true} requireSalon={true}>
          <SGIntroPage/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.sgAddLines}>
        <RoutingGuard requireAuth={true} requireSalon={true}>
          <AddLinesV2Page showBreadCrumbs={false}/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.sgAddLinesIntro}>
        <RoutingGuard requireAuth={true} requireSalon={true}>
          <SGAddLinesIntroPage/>
        </RoutingGuard>
      </Route>

      <Route path={ROUTES.sgAddPricingIntro}>
        <RoutingGuard requireAuth={true} requireSalon={true}>
          <SGPricingIntroPage/>
        </RoutingGuard>
      </Route>

      <Route path={ROUTES.sgAddTeamIntro}>
        <RoutingGuard requireAuth={true} requireSalon={true}>
          <SGTeamIntroPage/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.sgAddClientsIntro}>
        <RoutingGuard requireAuth={true} requireSalon={true}>
          <SGClientsIntroPage/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.sgAddTeam}>
        <RoutingGuard requireAuth={true} requireSalon={true}>
          <StylistsPage showBreadCrumbs={false}/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.sgAddClients}>
        <RoutingGuard requireAuth={true} requireSalon={true}>
          <ClientsPage showBreadCrumbs={false}/>
        </RoutingGuard>
      </Route>

      <Route path={ROUTES.sgAddAddonsIntro}>
        <RoutingGuard requireAuth={true} requireSalon={true}>
          <SGAddonsIntroPage/>
        </RoutingGuard>
      </Route>

      <Route path={ROUTES.sgLaborIntro}>
        <RoutingGuard requireAuth={true} requireSalon={true}>
          <SGLaborIntroPage/>
        </RoutingGuard>
      </Route>

      <Route path={ROUTES.sgAddonsOnboarding}>
        <RoutingGuard requireAuth={true} requireSalon={true}>
          <SuppliesOnboardingPage showBreadCrumbs={false}/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.sgAddons}>
        <RoutingGuard requireAuth={true} requireSalon={true}>
          <SuppliesPage showBreadCrumbs={false}/>
        </RoutingGuard>
      </Route>

      <Route path={ROUTES.sgAddExtensionsIntro}>
        <RoutingGuard requireAuth={true} requireSalon={true}>
          <SGExtensionsIntroPage/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.sgExtensions}>
        <RoutingGuard requireAuth={true} requireSalon={true}>
          <ExtensionsPage showBreadCrumbs={false}/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.sgAddExtensions}>
        <RoutingGuard requireAuth={true} requireSalon={true}>
          <ExtensionsAddPage showBreadCrumbs={false}/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.sgAddExtensionsV2}>
        <RoutingGuard requireAuth={true} requireSalon={true}>
          <AddExtensionsV2Page showBreadCrumbs={false}/>
        </RoutingGuard>
      </Route>

      <Route path={ROUTES.sgAddPricing}>
        <RoutingGuard requireAuth={true} requireSalon={true}>
          <LinesPage showBreadCrumbs={false}/>
        </RoutingGuard>
      </Route>

      <Route path={ROUTES.sgAutoPricing}>
        <RoutingGuard requireAuth={true} requireSalon={true}>
          <LinesPage showBreadCrumbs={false}/>
        </RoutingGuard>
      </Route>

      <Route path={ROUTES.sgSessionsIntro}>
        <RoutingGuard requireAuth={true} requireSalon={true}>
          <SGSessionsIntroPage/>
        </RoutingGuard>
      </Route>

      <Route path={ROUTES.sgAcademyIntro}>
        <RoutingGuard requireAuth={true} requireSalon={true}>
          <SGAcademyIntroPage/>
        </RoutingGuard>
      </Route>

      {/* inventory start */}
      {/**/}
      {/**/}
      {/**/}
      {/**/}
      <Route path={ROUTES.sgInventoryIntro}>
        <RoutingGuard requireAuth={true} requireSalon={true}>
          <SGInventoryIntroPage/>
        </RoutingGuard>
      </Route>

      <Route path={ROUTES.sgInventoryTargetsIntro}>
        <RoutingGuard requireAuth={true} requireSalon={true}>
          <SGInventoryTargetsIntroPage/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.sgInventoryTargets}>
        <RoutingGuard requireAuth={true} requireSalon={true}>
          <ProductTargetPage showBreadCrumbs={false}/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.sgInventoryOnHandIntro}>
        <RoutingGuard requireAuth={true} requireSalon={true}>
          <SGInventoryOnHandIntroPage/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.sgInventoryOnHand}>
        <RoutingGuard requireAuth={true} requireSalon={true}>
          <ProductOnHandPage showBreadCrumbs={false}/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.sgInventoryStockAlertsIntro}>
        <RoutingGuard requireAuth={true} requireSalon={true}>
          <SGInventoryAlertsIntroPage/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.sgInventoryOrdersIntro}>
        <RoutingGuard requireAuth={true} requireSalon={true}>
          <SGInventoryOrdersIntroPage/>
        </RoutingGuard>
      </Route>
      <Route path={ROUTES.sgInventoryAddingStockIntro}>
        <RoutingGuard requireAuth={true} requireSalon={true}>
          <SGInventoryAddingStockIntroPage/>
        </RoutingGuard>
      </Route>
      {/**/}
      {/**/}
      {/**/}
      {/**/}
      {/* inventory end */}

      <Route path={ROUTES.sgPOSIntro}>
        <RoutingGuard requireAuth={true} requireSalon={true}>
          <SGPOSIntroPage/>
        </RoutingGuard>
      </Route>

      <Route path={ROUTES.sgIndex}>
        <RoutingGuard requireAuth={true} requireSalon={true}>
          <SGIndexPage/>
        </RoutingGuard>
      </Route>
    </StartGuideSidenav>
  )
}

