import { generatePath } from "react-router-dom";
import {LoggedInUser} from "../../data/user/interfaces";
import {SalonTypes} from "../../data/salon/interfaces";
import {getCurrentPaymentStatus} from "../../data/user/utils";

export const buildPathToPageOrGuide = (params: {salonId: number, route: string, guideRoute: string, guideCompleteOrHasData: boolean, releaseStartGuideV2: boolean}): string => {
  const {salonId, route, guideRoute, guideCompleteOrHasData, releaseStartGuideV2} = params
  if (guideCompleteOrHasData || !releaseStartGuideV2) {
    return generatePath(route, { salonId })
  }
  return generatePath(guideRoute, {salonId})
}

export const getShowTeam = (user: LoggedInUser): boolean => {
  const paymentStatus = getCurrentPaymentStatus(user)
  const salonType = user?.currentSalonContext?.salonType
  return paymentStatus?.paymentSystemType === 'stripe'
    ? true
    : salonType === SalonTypes.salon
}