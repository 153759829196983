import { buildLaraConfig } from "../../mini-lib/lara/lara-utils";
import { GetServerBaseUrl } from "../../env";
import axios from "axios";
import { Dispatch } from "@reduxjs/toolkit";
import {getEnvConfig} from "../../config";

export const apiSalonSnapshotSync = ( params: { token: string, userId: number, salonId: number } ): Promise<any> => {
  const { token, salonId } = params
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl('v3', 'lara')}/salon-snapshots/build-and-sync`
  const body = {
      "build": "true",
      "sync": "true",
      "include_salon": true,
      "include_session": true,
      "include_milestone": true,
      "include_product": true,
      "salon_ids": [salonId]
  }
  return axios
    .post(url, body, config)
    .then(() => {
      return
    })
    .catch(( error ) => {
      throw error
    })
}

export const dispatchSalonSnapshotSync = (params: { token: string, userId: number, salonId: number} ) => {
  const {isProd} = getEnvConfig()

  return ( dispatch: Dispatch ) => {
    if (isProd) {
      return apiSalonSnapshotSync(params).then(() => {})
    }
  }
}
