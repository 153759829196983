import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { Badge, Box, Button, Circle, Drawer, DrawerBody, DrawerContent, DrawerOverlay, Flex, Text } from '@chakra-ui/react'
import { UseSignOut } from '../../core/hooks/UseSignOut'
import { useLocation } from 'react-router-dom'

import { redirectToAngularAdmin } from '../../data/user/utils'
import { COLORS } from '../theme/colors'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../hooks'
import { selectSidenavVisible, setNavVisible, selectSubSideNavVisible, setSubSidenavVisible } from './slice'
import { UseViewSize } from '../../core/UseViewSize'
import { SALONSCALE_LOGO_URL, SIDENAV_WIDTH, SECONDARY_SIDENAV_WIDTH } from '../../data/constants'
import { SalonScaleIcon } from '../icons/SalonScaleIcon'
import { ReleaseMultiLocation } from '../flags/Release'
import { MultiSalonButton } from '../../pages/multi-salon/MultiSalonButton'
import { MaterialIcon } from '../../mini-lib/icons/MaterialIcon'
import { getEnvConfig } from "../../config";
import { FeatureLocked } from "../payment-status/PaymentStatus";

export const Sidenav = (props: {
  header: any
  content: any
  showLogout: boolean
  showStopImpersonating: boolean
  children: any
  subNav?: any
}) => {
  const dispatch = useDispatch()
  const navVisible = useAppSelector(selectSidenavVisible)
  const isSubSidenavOpen = useAppSelector(selectSubSideNavVisible)
  const { children, header, content, showLogout, showStopImpersonating, subNav = <></> } = props
  const { showSideNavAsSheet, isMobile } = UseViewSize()
  const offsetMargin = showSideNavAsSheet ? '' : (isSubSidenavOpen ? SECONDARY_SIDENAV_WIDTH : SIDENAV_WIDTH)
  const location = useLocation()
  React.useEffect(() => {
    dispatch(setSubSidenavVisible(Boolean(location.pathname.includes('reports'))))
  }, [location, dispatch])

  React.useEffect(() => {
    if (showSideNavAsSheet) {
      dispatch(setSubSidenavVisible(navVisible && Boolean(location.pathname.includes('reports'))))
    }
  }, [dispatch, navVisible, location.pathname, showSideNavAsSheet])

  // TODO: make this condition dynamic for all routes
  const renderIconButton = Boolean(location.pathname.includes('reports'))

  return (
    <>
      {/* mobile nav */}
      {isMobile ?
        <Drawer placement="left" onClose={() => dispatch(setNavVisible(false))} isOpen={navVisible} size='xs'>
          <DrawerOverlay />
          <DrawerContent padding={0}>
            <DrawerBody padding={0} bg={COLORS.shades_neutral_150}>
              {isMobile && renderIconButton && <Box display='flex' background={isSubSidenavOpen ? "#FAFAFA" : "white"} padding='24px 24px 0px 24px'>
                {isSubSidenavOpen ? <>
                  <MaterialIcon
                    colorhex={COLORS.lavender_500}
                    onClick={() => { dispatch(setSubSidenavVisible(!isSubSidenavOpen)) }}
                    name='chevron_left'
                  />
                  <Text color={COLORS.lavender_500}>back</Text>
                </>
                  :
                  <>
                    <MaterialIcon
                      colorhex={COLORS.lavender_500}
                      onClick={() => { dispatch(setNavVisible(false)) }}
                      name='close'
                    />
                    <Text color={COLORS.lavender_500}>close</Text>
                  </>}
              </Box>}
              <Box display='flex'>
                {!isSubSidenavOpen && <Box w='100%'>
                  <SidenavContent
                    header={header}
                    content={content}
                    showLogout={showLogout}
                    showStopImpersonating={showStopImpersonating}
                  />
                </Box>}
                
                {navVisible && isSubSidenavOpen && <Box bg={COLORS.shades_neutral_50} w={isSubSidenavOpen ? '100%' : '50%'}>{subNav}</Box>}
              </Box>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
        :
        // {/* tablet nav */}
        <Drawer placement="left" onClose={() => dispatch(setNavVisible(false))} isOpen={navVisible} size={isSubSidenavOpen ? 'lg':'xs'}>
          <DrawerOverlay />
          <DrawerContent padding={0}>
            <DrawerBody padding={0}>
              <Box display='flex'>
                {<Box w='100%'>
                  <SidenavContent
                    header={header}
                    content={content}
                    showLogout={showLogout}
                    showStopImpersonating={showStopImpersonating}
                  />
                </Box>}

                {navVisible && isSubSidenavOpen && <Box bg={COLORS.shades_neutral_50} w={isSubSidenavOpen ? '100%' : '50%'}>{subNav}</Box>}
              </Box>
            </DrawerBody>
          </DrawerContent>
        </Drawer>}

      {/* desktop nav */}
      <Box bg={isSubSidenavOpen ? COLORS.shades_neutral_50 : 'white'} position="fixed" left={0} top={0} zIndex='2'>
        {!showSideNavAsSheet ? (
          <Box
            bg={COLORS.shades_neutral_150}
            w={SIDENAV_WIDTH}
            minH="100vh"
            h="100%"
            boxSizing="border-box"
            border="1px solid rgba(0,0,0,.12)"
          >
            <SidenavContent
              header={header}
              content={content}
              showLogout={showLogout}
              showStopImpersonating={showStopImpersonating}
            />
          </Box>
        ) : (
          <></>
        )}
      </Box>
      <Box marginLeft={offsetMargin}>{children}</Box>
      {showSideNavAsSheet && navVisible && subNav}
      {!showSideNavAsSheet && isSubSidenavOpen && !navVisible && subNav}
    </>
  )
}

export const SidenavContent = (props: {
  header: any
  content: any
  showLogout: boolean
  showStopImpersonating: boolean
}) => {
  const { user } = UseBaseApiParams()
  const config = getEnvConfig()
  const { header, content, showLogout, showStopImpersonating } = props
  return (
    <Flex flex="1" direction="column" justify="space-between" h="100vh" overflowX="scroll" autoFocus={false}>
      <Box>
        {header}
        {content}
      </Box>
      <Box>
        {showLogout && (
          <Flex m="24px" align="end" justify="center" autoFocus={true}>
            <Button variant="ghost" colorScheme="brand.lavender" autoFocus={true} onClick={UseSignOut}>
              Sign Out
            </Button>
          </Flex>
        )}
        {/* todo when we update user tokens change this */}
        {showStopImpersonating && (
          <Flex mt="12px" align="end" justify="center" autoFocus={true} bg={COLORS.lavender_100}>
            <Button w="100%" variant="ghost" onClick={() => redirectToAngularAdmin(user.token, user.userId, config.angularHost)}>
              Stop Impersonating
            </Button>
          </Flex>
        )}
      </Box>
    </Flex>
  )
}
export const SidenavHeader = () => {
  const releaseMultiLocation = ReleaseMultiLocation()
  const { user } = UseBaseApiParams()
  return (
    <>
      {releaseMultiLocation && <MultiSalonButton />}
      {!releaseMultiLocation && (
        <>
          <Flex justify="center" align="center" m="24px 0">
            <Flex justify="center" align="center" w="48px" h="48px" p="4px" borderRadius="12px" bg="brand.lavender.500">
              <img alt="logo" style={{ height: '100%', width: '100%', padding: '4px' }} src={SALONSCALE_LOGO_URL} />
            </Flex>
          </Flex>

          <Flex justify="center" align="center" m="24px 0 12px 0">
            <Circle size="89px" bg={COLORS.lavender_100} border="2px solid" borderColor="brand.lavender.500">
              {user && user.photoUrl ? (
                <img
                  alt="stylist logo"
                  src={user.photoUrl}
                  style={{ height: '100%', width: '100%', borderRadius: '50%', margin: '2px' }}
                />
              ) : (
                <i style={{ height: '100%', width: '100%', fontSize: '85px' }} className="material-icons-round">
                  account_circle
                </i>
              )}
            </Circle>
          </Flex>
          <Flex m="0 0 24px 0" direction="column" align="center" justify="center">
            <div style={{ fontSize: '20px' }}>
              {user?.firstName} {user?.lastName}
            </div>
            <Box style={{ fontSize: '16px' }} color={COLORS.text_secondary}>
              Account Owner
            </Box>
          </Flex>
        </>
      )}
    </>
  )
}

const isSelectedStyle = {
  color: COLORS.lavender_500,
  background: COLORS.lavender_100,
  borderRadius: '8px',
}

export const SidenavTile = (props: { iconName?: string; isLocked?: boolean; badgeName?: string; label: string; isSelected: boolean }) => {
  const { iconName, isLocked, badgeName, label, isSelected } = props
  return (
    <Flex
      _hover={{ bg: COLORS.hover }}
      align="center"
      p="12px"
      m='0 12px'
      gridGap="12px"
      style={isSelected ? isSelectedStyle : {}}
    >
      {iconName ? <SalonScaleIcon name={iconName} /> : <Box w="12px" />}
      {label} {badgeName && <Badge colorScheme="brand.lavender">{badgeName}</Badge>}
      {isLocked && <FeatureLocked colorhex={COLORS.lavender_500}/>}
    </Flex>
  )
}
